/**
 * Announcements
 */
/* eslint-disable */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// async components
import {
  AsyncManageAnnouncementsComponent,
  AsyncAddAnnouncementComponent,
} from "../../components/AsyncComponent/AsyncComponent";

const Forms = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/manage`} />
      <Route
        path={`${match.url}/view-all`}
        component={AsyncManageAnnouncementsComponent}
      />
      <Route
        path={`${match.url}/create`}
        component={AsyncAddAnnouncementComponent}
      />
    </Switch>
  </React.Fragment>
);

export default Forms;
