// Checkbox.js
import React, { useState } from "react";
import {
  ITQANDropDownMinus,
  ITQANDropDownPlug,
  ITQANDropDownPlus,
} from "../constants";

const ITQANDropDownItem = ({
  option,
  selectedOptions,
  onCheckboxChange,
  isChild,
  searchQuery,
}) => {
  const isChecked = selectedOptions.includes(option.id);
  const [collapsedOptions, setCollapsedOptions] = useState({});
  const handleCheckboxChange = () => {
    onCheckboxChange(option);
  };

  const toggleCollapse = (optionId) => {
    setCollapsedOptions((prevCollapsedOptions) => ({
      ...prevCollapsedOptions,
      [optionId]: !prevCollapsedOptions[optionId],
    }));
  };

  return (
    <div
      key={option.id}
      className={`position-relative ${
        isChild ? "ms-4 mt-2 d-flex flex-column" : ""
      }`}
      style={{ marginTop: "20px", minHeight: "24px" }}
      forname={option.name.toLowerCase()}
    >
      <div className={`${isChild ? "child" : "d-none"}`}></div>
      <label
        className={`position-relative d-flex align-items-center ${
          selectedOptions.includes(option.id) ? "checked" : ""
        }`}
      >
        <input
          type="checkbox"
          className="checkbox border-color"
          checked={selectedOptions.includes(option.id)}
          id={option.id}
          onChange={() => {
            handleCheckboxChange();
          }}
        />
        <span
          style={{
            marginLeft: "8px",
            fontWeight:
              searchQuery &&
              option.name.toLowerCase().includes(searchQuery.toLowerCase())
                ? "bold"
                : "initial",
            color: selectedOptions.includes(option.id) ? "#1F9E8D" : "",
          }}
        >
          {option.name}
        </span>
      </label>
      {option.children && option.children.length > 0 && (
        <button
          type="button"
          className="position-absolute btn btn-style"
          onClick={() => toggleCollapse(option.id)}
        >
          {collapsedOptions[option.id] ? (
            <span>
              <i>
                <ITQANDropDownMinus className="dropdown-icon-p" />
              </i>
            </span>
          ) : (
            <span>
              <i>
                <ITQANDropDownPlus className="dropdown-icon-p" />
              </i>
            </span>
          )}
        </button>
      )}
      {option.children && option.children.length > 0 && (
        <>
          <div className="child-ind"></div>
          <div
            className={`position-relative ${
              collapsedOptions[option.id] ? "slide-up" : "slide-down"
            }`}
          >
            {option.children.map((child) => (
              <ITQANDropDownItem
                key={child.id}
                option={child}
                selectedOptions={selectedOptions}
                onCheckboxChange={onCheckboxChange}
                searchQuery={searchQuery}
                isChild
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ITQANDropDownItem;
