import React from "react";
import ReactApexChart from "react-apexcharts";
import {
  InsightDurationDaily,
  InsightDurationMonthly,
  InsightDurationWeekly,
} from "../../../constants";

export const ApplicationsChartElement = ({
  data,
  options,
  series,
  lang,
  activeChoice,
  setActiveChoice,
  selectDataType,
}) => {
  return (
    <>
      <select
        className="form-select inter-bold graph-filters-type-selector"
        onChange={(e) => {
          selectDataType(e.target.value);
        }}
        style={{
          width: "12rem",
          display: "inline",
          fontSize: "1rem",
          border: "none",
        }}
      >
        <option value="total-applications">
          {window.sys_app_translate("Total Applications", lang)}
        </option>
        <option value="registered-students">
          {window.sys_app_translate("Registered Students", lang)}
        </option>
      </select>
      <div className="graph-filters-container">
        <ul>
          <li
            className={
              "inter inter-medium " +
              (activeChoice === InsightDurationMonthly ? "active" : "")
            }
            onClick={() => {
              setActiveChoice(InsightDurationMonthly);
            }}
            style={{ userSelect: "none" }}
          >
            {window.sys_app_translate("Monthly", lang)}
          </li>
          <li
            className={
              "inter inter-medium " +
              (activeChoice === InsightDurationWeekly ? "active" : "")
            }
            onClick={() => {
              setActiveChoice(InsightDurationWeekly);
            }}
            style={{ userSelect: "none" }}
          >
            {window.sys_app_translate("Weekly", lang)}
          </li>
          <li
            className={
              "inter inter-medium " +
              (activeChoice === InsightDurationDaily ? "active" : "")
            }
            style={{ userSelect: "none" }}
            onClick={() => {
              setActiveChoice(InsightDurationDaily);
            }}
          >
            {window.sys_app_translate("Daily", lang)}
          </li>
        </ul>
      </div>
      <div
        id="chart"
        style={{ position: "relative" }}
        className="profits-chart"
      >
        <ReactApexChart
          options={{
            chart: {
              type: "bar",
              height: "100%",
              background: "#ffffff",
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                horizontal: false,
                columnWidth: "30px",
              },
            },
            colors: ["#199B8B"],
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: series,
            },
          }}
          series={[
            {
              data: data,
            },
          ]}
          type="bar"
          height={230}
        />
      </div>
    </>
  );
};
