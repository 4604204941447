/**
 * Universities
 */
/* eslint-disable */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// async components
import {
  AsyncManageUniversitiesComponent,
  AsyncViewUniversityComponent,
  AsyncAddUniversityComponent,
  AsyncModifyUniversityComponent,
} from "../../components/AsyncComponent/AsyncComponent";

const Forms = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/manage`} />
      <Route
        path={`${match.url}/view-all`}
        component={AsyncManageUniversitiesComponent}
      />
      <Route
        path={`${match.url}/view`}
        component={AsyncViewUniversityComponent}
      />
      <Route
        path={`${match.url}/create`}
        component={AsyncAddUniversityComponent}
      />
      <Route
        path={`${match.url}/modify`}
        component={AsyncModifyUniversityComponent}
      />
    </Switch>
  </React.Fragment>
);

export default Forms;
