import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import {
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Select from "react-select";
import { Select as MaterialSelect } from "@material-ui/core";
import {
  AnnouncementGreenImageUpload,
  AnnouncementGreenReelUpload,
  AnnouncementGreenVideoUpload,
  AnnouncementImageUpload,
  AnnouncementReelUpload,
  AnnouncementVideoUpload,
  DateFieldIcon,
  DownloadIconForForm,
  SystemAvatar,
  UploadFileButton,
} from "../constants/SVGIcons";
import { ITQChoices, ITQModal } from "../libs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AppConfig from "../constants/AppConfig";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Icon as ReactifyIcon } from "@iconify/react";
import { Editor } from "@tinymce/tinymce-react";
import InputMask from "react-input-mask";
import JsFileDownloader from "js-file-downloader";
import ImageCropper from "../components/ImageCropper";
import {
  FileInputAllowedTypes,
  ImageFileTypes,
} from "../constants/FileInputTypes";
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";

export const validationMessages = (error, fieldName = "This field", lang) => {
  if (error.type === "required") {
    return (
      window.sys_app_translate(fieldName, lang) +
      " " +
      window.sys_app_translate("is required", lang)
    );
  }
  if (error.type === "pattern") {
    switch (fieldName) {
      default:
        return (
          window.sys_app_translate(fieldName, lang) +
          " " +
          window.sys_app_translate("is not correct", lang)
        );
    }
  }
  if (error.type === "custom") {
    return error.message;
  }
};
export const _getNormalInput = ({
  control,
  inputName,
  inputTitle,
  inputId,
  errors,
  lang,
  type = "text",
  width = "col-4",
  inputSize = "medium",
  required = true,
  preventNonLatinLetters = true,
  prefix = null,
  suffix = null,
  capitalize = false,
  attachPrefixToTitle = true,
  defaultValue = "",
}) => {
  let onKeyPress = () => {};
  switch (type) {
    case "number":
      onKeyPress = (e) => {
        if (
          (e.which != 8 && e.which != 0 && e.which < 48 && e.which !== 46) ||
          e.which > 57
        ) {
          e.preventDefault();
        }
      };
      break;
    case "text":
      if (preventNonLatinLetters) {
        onKeyPress = window.preventNonLatinLetters;
      }
      break;
  }
  const transformToUppercase = (e) => {
    e.target.value = e.target.value.toUpperCase();
  };
  return (
    <div className={`${width}`}>
      <Controller
        name={inputName}
        control={control}
        rules={{
          required: required,
        }}
        render={({ field }) => (
          <TextField
            id={inputId}
            size={inputSize}
            multiline={type === "textarea" ? true : false}
            defaultValue={defaultValue}
            type={type}
            rows={type === "textarea" ? 4 : 1}
            onKeyPress={onKeyPress}
            onInput={capitalize ? transformToUppercase : null}
            className={`itqan-text-field `}
            label={
              <>
                {attachPrefixToTitle && typeof prefix === "string" ? (
                  <>
                    <ReactifyIcon
                      icon={prefix}
                      fontSize={"1.33rem"}
                      color={AppConfig.themeColors.primary}
                    />
                  </>
                ) : null}
                {attachPrefixToTitle && typeof prefix !== "string"
                  ? prefix
                  : null}
                <span className="mx-1"></span>
                {window.sys_app_translate(inputTitle, lang)}
                <span className="mx-1">{required ? "*" : null}</span>
              </>
            }
            variant="outlined"
            error={!!errors[inputName]}
            helperText={
              errors[inputName] &&
              validationMessages(errors[inputName], inputTitle)
            }
            InputProps={{
              startAdornment: prefix ? <></> : null,
              endAdornment: suffix ? (
                <InputAdornment position="end">{suffix}</InputAdornment>
              ) : null,
            }}
            {...field}
          />
        )}
      />
    </div>
  );
};
export const _getSelectInput = ({
  control,
  inputName,
  options,
  errors,
  inputTitle,
  lang,
  multiple = false,
  width = "col-4",
  required = true,
  onChange = (e) => {},
  disabled = false,
  startIcon = null,
  defaultValue = null,
}) => {
  console.log("gettig select input: " + inputName);
  return (
    <div className={width} style={{ position: "relative" }}>
      <InputLabel
        htmlFor="country"
        className="mb-2 px-2 itqan-react-select-field-label"
        style={{
          width: "fit-content",
        }}
      >
        {startIcon && typeof startIcon === "string" && (
          <ReactifyIcon
            icon={startIcon}
            color={AppConfig.themeColors.primary}
            fontSize="1.33rem"
          />
        )}
        {startIcon && typeof startIcon !== "string" && startIcon}
        <span className={`mx-1 ${errors[inputName] ? "text-danger" : ""}`}>
          {window.sys_app_translate(inputTitle, lang)}
        </span>
        <span>{required ? "*" : null}</span>
      </InputLabel>
      <Controller
        name={inputName}
        control={control}
        rules={{
          required: required,
          onChange: onChange,
        }}
        render={({ field }) => (
          <React.Fragment>
            <Select
              className={`itqan-react-select-field ${
                errors[inputName] ? "itqan-react-select-field-error" : ""
              }`}
              style={{ gap: "0.6rem" }}
              options={options}
              isMulti={multiple}
              isDisabled={disabled}
              defaultValue={defaultValue}
              isSearchable={true}
              // placeholder={window.sys_app_translate(inputTitle, lang)}
              {...field}
              // components={
              //   startIcon
              //     ? {
              //         Placeholder: (props) => (
              //           <div
              //             style={{
              //               display: "flex",
              //               alignItems: "center",
              //             }}
              //           >
              //             {startIcon}
              //             <components.Placeholder {...props} />
              //           </div>
              //         ),
              //       }
              //     : null
              // }
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  boxShadow: "none",
                  border:
                    state.isFocused &&
                    "1px solid " + AppConfig.themeColors.primary,
                }),
                menu: (provided, state) => ({
                  ...provided,
                  border: "1px solid " + AppConfig.themeColors.primary,
                  boxShadow: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor:
                    state.isFocused && AppConfig.themeColors.primary,
                  color: state.isFocused && "white",
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                  maxHeight: "100px",
                  overflowY: "auto",
                }),
              }}
            />
            {errors[inputName] && (
              <div style={{ marginTop: "5px" }}>
                <p className="itqan-react-select-field-error-text my-0">
                  {errors[inputName] &&
                    validationMessages(errors[inputName], inputTitle)}
                </p>
              </div>
            )}
          </React.Fragment>
        )}
      />
    </div>
  );
};
export const _getMaterialSelectInput = ({
  control,
  inputName,
  options = [],
  errors,
  lang,
  width = "col-4",
  required = true,
  onChange = (e) => {},
  disabled = false,
  defaultValue = null,
}) => {
  return (
    <Controller
      name={inputName}
      control={control}
      rules={{
        required: required,
        onChange: onChange,
      }}
      render={({ field }) => (
        <>
          <MaterialSelect
            key={inputName + Math.random()}
            name={inputName}
            defaultValue={defaultValue}
            disabled={disabled}
            {...field}
          >
            {options.map((option) => {
              return <MenuItem value={option.value}>{option.label}</MenuItem>;
            })}
          </MaterialSelect>
          {errors[inputName] && (
            <div>
              <p className="itqan-react-select-field-error my-0">
                {errors[inputName] && (
                  <span style={{ color: AppConfig.themeColors.danger }}>
                    **
                  </span>
                )}
              </p>
            </div>
          )}
        </>
      )}
    />
  );
};
export const _getDatePicker = ({
  control,
  inputName,
  inputTitle,
  errors,
  lang,
  width = "col-4",
  required = true,
  prefix = null,
}) => {
  return (
    <div className={width}>
      <Controller
        name={inputName}
        control={control}
        rules={{
          required: required,
        }}
        render={({ field }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              variant="dialog"
              size="medium"
              inputVariant="outlined"
              format="dd.MM.yyyy"
              InputLabelProps={{
                shrink: true,
              }}
              className="itqan-date-field itqan-text-field"
              label={
                <>
                  {!prefix && <DateFieldIcon />}
                  {prefix && (
                    <ReactifyIcon
                      icon={prefix}
                      fontSize={"1.7rem"}
                      color={AppConfig.themeColors.primary}
                    />
                  )}
                  <span className="mx-1"></span>
                  {window.sys_app_translate(inputTitle, lang)}
                  <span className="mx-1">{required ? "*" : null}</span>
                </>
              }
              error={!!errors[inputName]}
              helperText={
                errors[inputName] &&
                validationMessages(
                  errors[inputName],
                  window.sys_app_translate(inputTitle, lang)
                )
              }
              {...field}
            />
          </MuiPickersUtilsProvider>
        )}
      />
    </div>
  );
};
export const _getPhoneInput = ({
  control,
  inputName,
  inputId,
  inputTitle,
  errors,
  lang,
  width = "col-4",
  required = true,
  prefix = null,
  suffix = null,
  attachPrefixToTitle = true,
}) => {
  const onKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 43) {
      e.preventDefault();
    }
  };
  return (
    <div className={width}>
      <Controller
        name={inputName}
        control={control}
        rules={{
          required: required,
          pattern: {
            value:
              /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
            message: "Invalid phone number",
          },
        }}
        render={({ field }) => (
          <TextField
            id={inputId}
            size="medium"
            type="text"
            className="itqan-text-field itqan-text-field-capitalize"
            label={
              <>
                {attachPrefixToTitle ? prefix : null}
                <div className="mx-1 d-inline"></div>
                {window.sys_app_translate(inputTitle, lang)}
              </>
            }
            variant="outlined"
            error={!!errors[inputName]}
            onKeyPress={onKeyPress}
            helperText={
              errors[inputName] &&
              validationMessages(errors[inputName], inputTitle)
            }
            InputProps={{
              startAdornment: prefix ? <></> : null,
              endAdornment: suffix ? (
                <InputAdornment position="end">{suffix}</InputAdornment>
              ) : null,
            }}
            {...field}
          />
        )}
      />
    </div>
  );
};

export const _getPhoneInputV2 = ({
  international = true,
  defaultCountry = "RU",
  value,
  onChange,
  width = "col-6",
  register,
  errors,
  inputName,
  inputTitle,
}) => {
  return (
    <div className={width}>
      <PhoneInput
        international={international}
        defaultCountry={defaultCountry}
        value={value}
        onChange={onChange}
      />
      {errors ? (
        <span className="text-danger">
          {errors[inputName] &&
            validationMessages(errors[inputName], inputTitle)}
        </span>
      ) : null}
    </div>
  );
};
export const _getEmailInput = ({
  control,
  inputName,
  inputId,
  inputTitle,
  errors,
  lang,
  prefix = null,
  suffix = null,
  width = "col-4",
  required = true,
  preventNonLatinLetters = true,
  attachPrefixToTitle = true,
  bottomAlign = true,
}) => {
  return (
    <div className={`${width}`}>
      <Controller
        name={inputName}
        control={control}
        rules={{
          required: required,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address",
          },
        }}
        render={({ field }) => (
          <TextField
            id={inputId}
            size="medium"
            type="email"
            className={`itqan-text-field mb-1`}
            label={
              <>
                {attachPrefixToTitle && typeof prefix === "string" ? (
                  <ReactifyIcon
                    icon={prefix}
                    fontSize={"1.33rem"}
                    color={AppConfig.themeColors.primary}
                  />
                ) : null}
                {attachPrefixToTitle && typeof prefix !== "string"
                  ? prefix
                  : null}
                <span className="mx-1"></span>
                {window.sys_app_translate(inputTitle, lang)}
                <span className="mx-1">{required ? "*" : null}</span>
              </>
            }
            variant="outlined"
            error={!!errors[inputName]}
            helperText={
              errors[inputName] &&
              validationMessages(errors[inputName], inputTitle)
            }
            InputProps={{
              startAdornment: prefix ? <></> : null,
              endAdornment: suffix && (
                <InputAdornment position="end">{suffix}</InputAdornment>
              ),
            }}
            {...field}
          />
        )}
      />
    </div>
  );
};
export const _getCheckbox = ({
  inputName,
  inputTitle,
  inputId,
  isChecked,
  defaultChecked,
  onChange,
  style,
  lang,
  width = "col-4",
  disabled = false,
  control = null,
  register = null,
}) => {
  return (
    <div className={width}>
      <div className="form-check" style={style}>
        {!register && (
          <>
            {typeof isChecked !== "undefined" && (
              <input
                className="form-check-input itqan-form-checkbox"
                name={inputName}
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
                id={inputId}
                disabled={disabled}
              />
            )}
            {typeof defaultChecked !== "undefined" && (
              <input
                className="form-check-input itqan-form-checkbox"
                name={inputName}
                type="checkbox"
                defaultChecked={defaultChecked}
                id={inputId}
                disabled={disabled}
              />
            )}
          </>
        )}
        {register && (
          <input
            type="checkbox"
            className="form-check-input itqan-form-checkbox"
            {...register(inputName)}
            onChange={onChange}
          />
        )}
        <label className="form-check-label" htmlFor={inputId}>
          {window.sys_app_translate(inputTitle, lang)}
        </label>
      </div>
    </div>
  );
};
export const _getChoicesInput = ({
  inputTitle,
  activeItemId,
  choiceItems,
  onChosen,
  lang,
  width = "col-4",
}) => {
  return (
    <div className={width}>
      <ITQChoices
        title={window.sys_app_translate(inputTitle, lang) + ":"}
        activeItemId={activeItemId}
        items={choiceItems}
        onItemClick={(term) => {
          onChosen(term);
          return true;
        }}
        lang={lang}
      />
    </div>
  );
};
export const _getFileInput = ({
  register,
  inputName,
  inputTitle,
  inputId,
  errors,
  lang,
  width = "col-4",
  required = true,
  onChange = (e) => {},
}) => {
  return (
    <div className={width}>
      <label htmlFor="">
        {window.sys_app_translate(inputTitle, lang)}
        {register ? (
          <input
            type="file"
            id={inputId}
            required={required}
            className="form-control"
            accept="image/jpg"
            {...register(inputName, {
              required: false,
            })}
          />
        ) : (
          <input
            type="file"
            id={inputId}
            required={required}
            className="form-control"
            accept="image/jpg,image/png,application/pdf,image/jpeg"
            onChange={onChange}
          />
        )}
        {register ? (
          <span className="text-danger">
            {errors[inputName] &&
              validationMessages(errors[inputName], inputTitle)}
          </span>
        ) : null}
      </label>
    </div>
  );
};
export const _getFileOutput = ({
  outputTitle,
  fileUrl,
  lang,
  width = "col-12",
}) => {
  return (
    <div
      className={width}
      style={{ marginTop: "1rem" }}
      onContextMenu={(e) => {
        e.preventDefault();
        new JsFileDownloader({
          url: fileUrl,
        });
      }}
    >
      <a
        href={fileUrl}
        target="_blank"
        className="inter inter-medium d-flex align-items-center"
        style={{
          color: "black",
          textDecoration: "none",
        }}
      >
        <DownloadIconForForm className="mx-1" />
        {window.sys_app_translate(outputTitle, lang)}
      </a>
    </div>
  );
};
export const _getPhotoInput = ({
  previewImage,
  inputName,
  inputTitle,
  inputDesc = "500px x 500px, Max size: 1 MB",
  lang,
  previewIdImage = () => {},
  errors,
}) => {
  let uploadFileInputRef = React.useRef(null);
  return (
    <div className="col-4 offset-1 col-md-3 offset-md-0 col-lg-3 offset-lg-0">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          width: "12.5rem",
          height: "12.5rem",
          backgroundColor: "white",
          borderRadius: "9px",
          border: "1px solid #E0E0E0",
          cursor: "pointer",
        }}
        onClick={() => {
          uploadFileInputRef.click();
        }}
      >
        {previewImage ? (
          <img
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "9px",
            }}
            src={
              previewImage.includes("istudents") ||
              previewImage.includes("iuniversities")
                ? AppConfig.storagePath + previewImage
                : previewImage
            }
          />
        ) : (
          <div
            className="text-center"
            style={{ fontSize: "14px", color: "#434343" }}
          >
            <div>
              <UploadFileButton />
            </div>
            {window.sys_app_translate("upload " + inputTitle, lang)}
          </div>
        )}
      </div>
      <div
        style={{
          fontSize: "1.125rem",
          marginTop: "1.08rem",
          textAlign: "center",
          width: "12.5rem",
        }}
      >
        {window.sys_app_translate(inputTitle, lang)}
      </div>
      <input
        name={inputName}
        style={{ display: "none" }}
        type="file"
        ref={(input) => (uploadFileInputRef = input)}
        onChange={(e) => {
          previewIdImage(e.target.files[0]);
        }}
      />
      <div
        style={{
          fontSize: "11px",
          textAlign: "center",
          width: "12.5rem",
        }}
      >
        {window.sys_app_translate("Image dimensions: " + inputDesc, lang)}
      </div>
      <div style={{ fontSize: "11px", color: "red" }}>
        {errors[inputName] &&
          validationMessages(
            errors[inputName],
            window.sys_app_translate(inputTitle, lang)
          )}
      </div>
    </div>
  );
};
export const _getPhotoInputV2 = ({
  inputName,
  inputTitle,
  inputValue,
  setValue,
  lang,
  register,
  errors,
  required = true,
}) => {
  console.log("inputValue: ", inputValue);
  const [previewImage, setPreviewImage] = React.useState(
    inputValue ? AppConfig.storagePath + inputValue : null
  );
  const [showCropper, setShowCropper] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const doPreviewImage = (imageFile) => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(imageFile);
    }
  };
  const firstLoad = React.useRef(true);
  useEffect(() => {
    if (inputValue && typeof inputValue !== "string") {
      doPreviewImage(inputValue);
    }
  }, []);
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    } else {
      setShowCropper(true);
    }
  }, [imageFile]);
  const _getCropperModal = () => {
    return (
      showCropper && (
        <ITQModal
          isOpen={showCropper}
          isCenterd={true}
          cancelButtonText="x"
          cancelButtonClicked={() => {
            setShowCropper(false);
          }}
          size="md"
          bodyContent={
            <div className="row">
              <div className="col-12">
                <ImageCropper
                  imageFile={imageFile}
                  onCropComplete={(finalImage) => {
                    doPreviewImage(finalImage);
                    setValue(inputName, finalImage);
                    setShowCropper(false);
                  }}
                />
              </div>
            </div>
          }
          title={window.sys_app_translate("Image Cropper", lang)}
        />
      )
    );
  };
  let uploadFileInputRef = React.useRef(null);
  //on file changed
  const onFilesChanged = (files) => {
    if (files.length > 0) {
      doPreviewImage(files[0]);
      setValue(inputName, files[0]);
      setImageFile(files[0]);
    }
  };
  //handling drag and drop
  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow the drop
    if (!isDragOver) setIsDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false); // Reset drag over state

    const files = e.dataTransfer.files; // Get the dropped files
    if (files.length) {
      onFilesChanged(files);
    }
  };
  return (
    <>
      <div
        key={inputName + Math.random()}
        className="d-flex align-items-center justify-content-center c-image"
        style={{
          backgroundImage: previewImage ? `url(${previewImage})` : "none",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        onClick={() => {
          uploadFileInputRef.current.click();
        }}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {previewImage ? (
          <></>
        ) : (
          <div className="text-center" style={{ color: "#434343" }}>
            {/* <ReactifyIcon
              icon={"fluent:image-32-regular"}
              fontSize={"7.2em"}
              color="#C9C9C9"
            /> */}
            <SystemAvatar />
          </div>
        )}
      </div>
      <input
        style={{ display: "none" }}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        type="file"
        {...register(inputName, {
          required: required,
        })}
        ref={(input) => (uploadFileInputRef.current = input)}
        onChange={(e) => {
          onFilesChanged(e.target.files);
        }}
        accept={[FileInputAllowedTypes.JPEG, FileInputAllowedTypes.PNG]}
      />
      <div
        className="input-image"
        style={{ width: "16rem" }}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <ReactifyIcon icon={"fluent:image-32-regular"} />
        {previewImage ? (
          <>
            <span>
              {window.sys_app_translate(inputTitle + " Uploaded", lang)}
            </span>
            <ReactifyIcon
              icon={"fa6-solid:trash-can"}
              role="button"
              onClick={() => {
                setValue(inputName, null);
                inputValue = null;
                setPreviewImage(null);
              }}
            />
          </>
        ) : (
          <span
            role="button"
            onClick={() => {
              uploadFileInputRef.current.click();
            }}
          >
            {(required ? "* " : "") +
              window.sys_app_translate(inputTitle, lang)}
          </span>
        )}
        <ReactifyIcon icon="fa6-solid:arrow-up-from-bracket" />
      </div>
      <div
        style={{
          fontSize: "11px",
          color: AppConfig.themeColors.danger,
          fontWeight: "bold",
        }}
      >
        {errors[inputName] &&
          validationMessages(
            errors[inputName],
            window.sys_app_translate(inputTitle, lang)
          )}
      </div>
      {_getCropperModal()}
    </>
  );
};
export const _getRichTextEditor = ({
  inputName,
  inputTitle,
  required,
  control,
  width = "col-4",
  startIcon = null,
  lang,
  errors,
}) => {
  const editorRef = useRef();
  return (
    <div className={`${width}`}>
      <InputLabel
        htmlFor="country"
        className="d-flex align-items-center mb-2 px-2"
        style={{ gap: "0.6rem" }}
      >
        {startIcon}
        {window.sys_app_translate(inputTitle, lang)}
        <span className="mx-1">{required ? "*" : null}</span>
      </InputLabel>
      <Controller
        name={inputName}
        control={control}
        defaultValue=""
        rules={{
          required: required,
        }}
        render={({ field }) => (
          <>
            <Editor
              tinymceScriptSrc={
                "https://itqanapply.com/static/tinymce/tinymce.min.js"
              }
              onEditorChange={field.onChange}
              value={field.value}
              init={{
                branding: false,
                promotion: false,
                menubar: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                  "table",
                ],
                toolbar:
                  "undo redo | fontselect | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help | table",
                content_style:
                  "body { font-family:Inter,sans-serif; font-size:1rem }",
                font_formats:
                  "Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;AkrutiKndPadmini=Akpdmi-n",
              }}
            />
            {errors[inputName] && (
              <div>
                <p className="itqan-react-select-field-error my-0">
                  {errors[inputName] &&
                    validationMessages(errors[inputName], inputTitle)}
                </p>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};
export const _getIbanField = ({
  control,
  inputName,
  inputTitle,
  inputId,
  errors,
  lang,
  width = "col-4",
  inputSize = "medium",
  required = true,
  bottomAlign = false,
  prefix = null,
  suffix = null,
  attachPrefixToTitle = true,
}) => {
  return (
    <div
      className={`${width} ${bottomAlign ? " d-flex align-items-end " : ""}`}
    >
      <Controller
        name={inputName}
        control={control}
        rules={{
          required: required,
          pattern:
            /TR[a-zA-Z0-9]{2}s?([0-9]{4}s?){1}([0-9]{1})([a-zA-Z0-9]{3}s?)([a-zA-Z0-9]{4}s?){3}([a-zA-Z0-9]{2})s?/,
        }}
        render={({ field }) => (
          <InputMask
            mask="TR999999999999999999999999"
            maskChar="_"
            disabled={false}
            {...field}
          >
            {(inputProps) => (
              <TextField
                id={inputId}
                size={inputSize}
                className="itqan-text-field"
                label={
                  <>
                    {attachPrefixToTitle ? prefix : null}
                    <span className="mx-1"></span>
                    {window.sys_app_translate(inputTitle, lang)}
                    <span className="mx-1">{required ? "*" : null}</span>
                  </>
                }
                variant="outlined"
                error={errors.iban ? true : false}
                helperText={
                  errors.iban && validationMessages(errors.iban, inputTitle)
                }
                InputProps={{
                  startAdornment: prefix ? <></> : null,
                  endAdornment: suffix ? (
                    <InputAdornment position="end">{suffix}</InputAdornment>
                  ) : null,
                }}
                {...inputProps}
              />
            )}
          </InputMask>
        )}
      />
    </div>
  );
};
export const ApplicationFormFileInput = ({
  lang,
  icon,
  text,
  valueKey, //RHF value key
  setValue, //RHF setValue
  register,
  errors,
  setError,
  required = true,
  allowedTypes = [
    FileInputAllowedTypes.JPEG,
    FileInputAllowedTypes.PNG,
    FileInputAllowedTypes.PDF,
  ],
  showCropperOnSingleImage = true,
  onOneFileUploadComplete = (file) => {},
}) => {
  const uploadFileInputRef = useRef(null); //to control hidden file input which is the real one
  const uploadedFilesCount = useRef(0);
  const [state, setState] = useState({
    loaded: false,
  });
  //related to handling images
  const [showCropper, setShowCropper] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const _getCropperModal = () => {
    return (
      showCropper && (
        <ITQModal
          isOpen={showCropper}
          isCenterd={true}
          cancelButtonText="x"
          cancelButtonClicked={() => {
            setShowCropper(false);
          }}
          size="md"
          bodyContent={
            <div className="row">
              <div className="col-12">
                <ImageCropper
                  imageFile={imageFile}
                  onCropComplete={(finalImage) => {
                    //it is only one image, so we set it directly to the value
                    publishValue(`${valueKey}[0]`, finalImage);
                    onOneFileUploadComplete(finalImage);
                    setShowCropper(false);
                  }}
                />
              </div>
            </div>
          }
          title={window.sys_app_translate("Image Cropper", lang)}
        />
      )
    );
  };
  //publish value to upper component
  const publishValue = (key, value) => {
    setValue(key, value);
    uploadFileInputRef.current.value = null;
    setError(valueKey, null);
  };
  //related to handling drag and drop
  const [isDragOver, setIsDragOver] = useState(false);
  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow the drop
    if (!isDragOver) setIsDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false); // Reset drag over state

    const files = e.dataTransfer.files; // Get the dropped files
    onFilesChanged(files);
  };
  //on files changed
  const onFilesChanged = (files) => {
    console.log("files changed ...");
    const filesCount = files.length;
    if (filesCount) {
      for (let index = 0; index < filesCount; index++) {
        const file = files[index];
        publishValue(`${valueKey}[${index}]`, file);
        if (filesCount === 1 && ImageFileTypes.includes(file.type)) {
          if (showCropperOnSingleImage) {
            setImageFile(file);
            setShowCropper(true);
          } else {
            onOneFileUploadComplete(file);
          }
        }
      }

      uploadedFilesCount.current = filesCount;
      setState({
        loaded: true,
      });
    }
  };
  return (
    <div
      className="clickable"
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => {
        if (!state.loaded) {
          uploadFileInputRef.current.click();
        }
      }}
    >
      {/*real file input*/}
      <input
        id={text}
        style={{ display: "none" }}
        type="file"
        {...register(valueKey, {
          required: required,
        })}
        onChange={(e) => {
          onFilesChanged(e.target.files);
        }}
        ref={(input) => (uploadFileInputRef.current = input)}
        multiple={true}
        accept={allowedTypes.join(",")}
      />
      {/*shown element*/}
      <div className="input-image">
        <ReactifyIcon icon={icon} />
        {state.loaded ? (
          <>
            <span style={{ color: AppConfig.themeColors.success }}>
              {window.sys_app_translate(text, lang)}
            </span>
            <span>
              (
              {uploadedFilesCount.current +
                " " +
                window.sys_app_translate("File(s)", lang)}
              )
            </span>
            <ReactifyIcon
              icon={"fa6-solid:trash-can"}
              role="button"
              onClick={() => {
                for (
                  let index = 0;
                  index < uploadedFilesCount.current;
                  index++
                ) {
                  publishValue(`${valueKey}[${index}]`, null);
                }
                uploadFileInputRef.current = 0;
                setState({
                  loaded: false,
                });
              }}
            />
          </>
        ) : (
          <span role="button">
            {required ? " * " : " "}
            {window.sys_app_translate(text, lang)}
          </span>
        )}
        <ReactifyIcon icon="fa6-solid:arrow-up-from-bracket" role="button" />
      </div>
      {/*validation message*/}
      <div
        style={{
          fontSize: "11px",
          color: AppConfig.themeColors.danger,
          fontWeight: "bold",
        }}
        className="text-center"
      >
        {errors[valueKey] && validationMessages(errors[valueKey], text)}
      </div>
      {_getCropperModal()}
    </div>
  );
};
export const _getCheckboxGroup = ({
  options,
  name,
  register,
  onChange = (e) => {},
}) => {
  return (
    <div>
      {options.map((option, index) => (
        <div
          id={`${name.toUpperCase()}${index}`}
          key={`${name.toUpperCase()}${index}`}
          className="d-flex align-items-center"
        >
          <input
            type="checkbox"
            className="form-check-input itqan-form-checkbox"
            {...register(`${name}[${index}]`, {
              onChange: onChange,
            })}
          />
          <label
            htmlFor={`${name.toUpperCase()}${index}`}
            style={{ color: "#21827F" }}
            className="inter inter-medium"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};
export const _getRadioGroup = ({
  options,
  name,
  register,
  onChange = () => {},
}) => {
  return (
    <div>
      {options.map((option, index) => (
        <div
          id={`${name.toUpperCase()}${index}`}
          key={`${name.toUpperCase()}${index}`}
          className="d-flex align-content-center"
        >
          <input
            type="radio"
            {...register(name, {
              onChange: onChange,
            })}
            value={option.value}
            style={{ accentColor: AppConfig.themeColors.primary }}
          />
          <label
            htmlFor={`${name.toUpperCase()}${index}`}
            style={{ color: "#21827F" }}
            className="inter inter-medium"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};
export const _getAnnouncementMediaUpload = ({
  title,
  lang,
  register,
  showMessage,
  setValue,
}) => {
  const [videoFile, setVideoFile] = useState(null);
  const [reelFile, setReelFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const videoFileRef = useRef(null);
  const reelFileRef = useRef(null);
  const imageFileRef = useRef(null);

  return (
    <>
      <label
        className="inter inter-medium my-3"
        style={{ color: AppConfig.themeColors.primary }}
      >
        {window.sys_app_translate(title, lang)}
      </label>
      <div>
        <input
          type="file"
          required={false}
          onChange={(e) => {
            if (imageFile || reelFile) {
              showMessage({
                type: "warning",
                title: window.sys_app_translate(
                  "Please remove the image or reel file first",
                  lang
                ),
                message: window.sys_app_translate(
                  "You can not upload a video file while there is an image or reel file uploaded.",
                  lang
                ),
              });
              return;
            }
            if (e.target.files.length > 0) {
              setValue("videofile", e.target.files[0]);
              setVideoFile(e.target.files[0]);
            }
          }}
          ref={videoFileRef}
          accept="video/mp4"
          style={{ display: "none" }}
        />
        <input
          type="file"
          accept="image/png, image/jpeg"
          required={false}
          onChange={(e) => {
            if (reelFile || videoFile) {
              showMessage({
                type: "warning",
                title: window.sys_app_translate(
                  "Please remove the video or reel file first",
                  lang
                ),
                message: window.sys_app_translate(
                  "You can not upload an image file while there is a video or reel file uploaded.",
                  lang
                ),
              });
              return;
            }
            if (e.target.files.length > 0) {
              setValue("imagefile", e.target.files[0]);
              setImageFile(e.target.files[0]);
            }
          }}
          ref={imageFileRef}
          style={{ display: "none" }}
        />
        <input
          type="file"
          required={false}
          onChange={(e) => {
            if (imageFile || videoFile) {
              showMessage({
                type: "warning",
                title: window.sys_app_translate(
                  "Please remove the image or video file first",
                  lang
                ),
                message: window.sys_app_translate(
                  "You can not upload a reel file while there is an image or video file uploaded.",
                  lang
                ),
              });
              return;
            }
            if (e.target.files.length > 0) {
              setValue("reelfile", e.target.files[0]);
              setReelFile(e.target.files[0]);
            }
          }}
          ref={reelFileRef}
          accept="video/mp4"
          style={{ display: "none" }}
        />
      </div>
      <div className="d-flex justify-content-between ">
        <div className="d-flex flex-column justify-items-center">
          {videoFile ? (
            <AnnouncementGreenVideoUpload />
          ) : (
            <AnnouncementVideoUpload />
          )}
          <span className="my-2"></span>
          {videoFile ? (
            <ReactifyIcon
              icon={"fa6-regular:circle-xmark"}
              color="#21827F"
              className="mx-auto"
              role="button"
              onClick={() => {
                setValue("videofile", null);
                setVideoFile(null);
              }}
            />
          ) : (
            <ReactifyIcon
              icon={"fa6-solid:arrow-up-from-bracket"}
              color="#21827F"
              className="mx-auto"
              role="button"
              onClick={() => {
                videoFileRef.current.value = null;
                videoFileRef.current.click();
              }}
            />
          )}
        </div>
        <div className="d-flex flex-column justify-items-center">
          {imageFile ? (
            <AnnouncementGreenImageUpload
              color={AppConfig.themeColors.primary}
            />
          ) : (
            <AnnouncementImageUpload color={AppConfig.themeColors.primary} />
          )}
          <span className="my-2"></span>
          {imageFile ? (
            <ReactifyIcon
              icon={"fa6-regular:circle-xmark"}
              color="#21827F"
              className="mx-auto"
              role="button"
              onClick={() => {
                setValue("imagefile", null);
                setImageFile(null);
              }}
            />
          ) : (
            <ReactifyIcon
              icon={"fa6-solid:arrow-up-from-bracket"}
              color="#21827F"
              className="mx-auto"
              role="button"
              onClick={() => {
                imageFileRef.current.value = null;
                imageFileRef.current.click();
              }}
            />
          )}
        </div>

        <div className="d-flex flex-column justify-items-center">
          {reelFile ? (
            <AnnouncementGreenReelUpload />
          ) : (
            <AnnouncementReelUpload />
          )}
          <span className="my-2"></span>
          {reelFile ? (
            <ReactifyIcon
              icon={"fa6-regular:circle-xmark"}
              color="#21827F"
              className="mx-auto"
              role="button"
              onClick={() => {
                setValue("reelfile", null);
                setReelFile(null);
              }}
            />
          ) : (
            <ReactifyIcon
              icon={"fa6-solid:arrow-up-from-bracket"}
              color="#21827F"
              className="mx-auto"
              role="button"
              onClick={() => {
                reelFileRef.current.value = null;
                reelFileRef.current.click();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export const _getDateRangePicker = ({
  defaultValue = null,
  setValue,
  startdateName,
  enddateName,
  required = true,
  lang = "en",
}) => {
  return (
    <Flatpickr
      className="e-daterange-picker"
      onChange={(dates) => {
        if (!dates) {
          return;
        }
        if (!dates[1]) {
          return;
        }
        setValue(startdateName, moment(dates[0]).format("YYYY-MM-DD"));
        setValue(enddateName, moment(dates[1]).format("YYYY-MM-DD"));
      }}
      options={{
        mode: "range",
        defaultDate: defaultValue,
      }}
      style={{
        height: "2rem",
        width: "100%",
        borderRadius: "8px",
        border: "1px solid #c9c9c9",
      }}
      placeholder={window.sys_app_translate("Date Range", lang)}
      required={required}
    />
  );
};
export const _getDateTimePicker = ({
  defaultValue = null,
  setValue,
  dateTimeName,
  required = true,
  lang = "en",
}) => {
  return (
    <Flatpickr
      className="e-daterange-picker"
      onChange={(dates) => {
        if (dates.length > 0) {
          setValue(
            dateTimeName,
            moment(dates[0]).format("YYYY-MM-DD HH:mm:ss")
          );
        }
      }}
      options={{
        mode: "single",
        enableTime: true,
        dateFormat: "Y-m-d h:i K",
        time_24hr: false,
        defaultDate: defaultValue,
      }}
      style={{
        height: "2rem",
        width: "100%",
        borderRadius: "8px",
        border: "1px solid #c9c9c9",
      }}
      placeholder={window.sys_app_translate("Date and Time", lang)}
      required={required}
    />
  );
};
