import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import {
  AcademicStatusForDropdown,
  ApplicationStatusArray,
  CalendarIcon,
  FilterAcademicStatusIcon,
  FilterCityIcon,
  FilterCountryIcon,
  FilterDegreeIcon,
  FilterFacultyIcon,
  FilterKeywordIcon,
  FilterLanguageIcon,
  FilterNationalityIcon,
  FilterRepresentativeIcon,
  FilterStatusIcon,
  FilterUniversityIcon,
  InputSearchFiltersIcon,
  InputSearchIcon,
} from "../../../constants";
import AppConfig from "../../../constants/AppConfig";
import { InputAdornment, TextField } from "@material-ui/core";
import "flatpickr/dist/themes/light.css";
import { Icon } from "@iconify/react";

export default function Filters({
  props,
  agencies,
  universities,
  doFilter,
  exportToPDF,
  isRootAgency,
}) {
  const { lang } = props.user;
  const [show, setShow] = useState(false);
  const [state, setState] = useState({});
  const firstLoad = useRef(true);
  useEffect(() => {
    console.log("filters changed ...");
    if (!firstLoad.current) {
      doFilter(state);
    } else {
      firstLoad.current = false;
    }
  }, [state]);
  const agencyRef = useRef(null);
  const universityRef = useRef(null);
  const searchRef = useRef(null);
  const resetFilters = () => {
    if (agencyRef.current) {
      agencyRef.current.clearValue();
    }
    if (universityRef.current) {
      universityRef.current.clearValue();
    }
    if (searchRef.current) {
      searchRef.current.value = "";
    }
    setState({});
  };

  return (
    <>
      <div
        className="row filters-toggle-btn justify-content-between"
        style={{ marginBottom: !show ? "1rem" : "0" }}
      >
        <div className="col-3 d-flex align-items-center px-0">
          <div
            onClick={() => {
              setShow(!show);
            }}
            className="d-flex align-items-center filter-toggle-btn"
          >
            <Icon icon="mi:filter" fontSize={"2.5rem"}></Icon>
            <span
              className="mx-2 inter inter-medium clickable"
              style={{
                fontSize: "1.55rem",
                fontWeight: "bold",
              }}
            >
              {window.sys_app_translate("Search Filter", lang)}
            </span>
          </div>
        </div>
        <div className="col-3 d-flex align-items-center justify-content-end">
          <Icon
            icon="bx:reset"
            fontSize={"4.5rem"}
            role="button"
            color={AppConfig.themeColors.primary}
            onClick={() => {
              resetFilters();
            }}
          ></Icon>
          <Icon
            icon="bxs:file-pdf"
            fontSize={"4.5rem"}
            className="mx-1"
            role="button"
            color={AppConfig.themeColors.primary}
            onClick={() => {
              exportToPDF();
            }}
          ></Icon>
        </div>
      </div>
      <div
        className={`row my-3 filters-section ${show ? "active" : ""}`}
        style={{ rowGap: "15px" }}
      >
        {isRootAgency && (
          <div className="col-4">
            <Select
              className="itqan-react-select-field itqan-react-select-field-top-margin"
              isClearable={true}
              options={agencies}
              ref={agencyRef}
              isMulti={false}
              onChange={(e) => {
                setState({
                  ...state,
                  agencyid: e ? e.value : null,
                });
              }}
              placeholder={
                <>
                  <FilterNationalityIcon className="mx-1" />
                  {window.sys_app_translate("Agency", lang)}
                </>
              }
            />
          </div>
        )}
        <div className="col-4">
          <Select
            className="itqan-react-select-field itqan-react-select-field-top-margin"
            isClearable={true}
            ref={universityRef}
            options={universities}
            isMulti={false}
            onChange={(e) => {
              setState({
                ...state,
                universityid: e ? e.value : null,
              });
            }}
            placeholder={
              <>
                <FilterUniversityIcon className="mx-1" />
                {window.sys_app_translate("University", lang)}
              </>
            }
          />
        </div>
      </div>
    </>
  );
}
