import React, { useState, useMemo } from "react";
import { ScanFileIcon, UploadFileIcon } from "../constants";

const ITQChoices = React.memo(function ({
  title,
  items,
  onItemClick,
  lang,
  style = {},
  activeItemId,
  uploadButtonClickHandler,
  fixedActiveItemId = false,
  hideChoice = null,
}) {
  const [id, setId] = useState(activeItemId);
  const handler = (e) => {
    setId(e);
  };
  const ITQChoice = ({ data, index }) => {
    const textLength = data.text.length;
    let suggestedWidth = null;
    if (textLength > 10) {
      suggestedWidth = textLength * 0.6;
    }
    const style = { marginLeft: 0 };
    let isActive = false;
    if (id) {
      isActive = id === data.id;
    }
    if (activeItemId && !fixedActiveItemId) {
      isActive =
        activeItemId &&
        (activeItemId === data.id || activeItemId === data.text);
    }
    return (
      <a
        style={index === 0 ? style : null}
        onClick={(e) => {
          e.preventDefault();
          const result = onItemClick(data.text);
          if (result) {
            handler(data.id);
          }
        }}
        className={`itqan-choice ${(isActive && "active") || ""}`}
      >
        {data.icon}
        <span className="inter inter-semi-bold" style={{ fontSize: "0.9rem" }}>
          {window.sys_app_translate(data.text, lang)}
        </span>
      </a>
    );
  };
  return (
    <div className="itqan-choices" style={style}>
      {title && (
        <span
          className="itqan-choices-title inter inter-semi-bold"
          style={{ fontSize: "1rem" }}
        >
          {title}
        </span>
      )}
      {items.map((item, key) => {
        if (hideChoice) {
          if (item.text === hideChoice) {
            return null;
          } else {
            return <ITQChoice key={"item" + key} data={item} index={key} />;
          }
        } else {
          return <ITQChoice key={"item" + key} data={item} index={key} />;
        }
      })}
      {uploadButtonClickHandler && (
        <a
          style={null}
          onClick={(e) => {
            e.preventDefault();
            uploadButtonClickHandler();
          }}
          className={`itqan-choice active`}
        >
          {/*icon file*/}
          <span>
            <ScanFileIcon width={"1.5rem"} height={"2rem"} />
          </span>
        </a>
      )}
    </div>
  );
});
export default ITQChoices;
