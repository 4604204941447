import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  setLoading,
  showMessage,
  setCurrentRecord,
  handleAppError,
  clearFormErrors,
} from "../../actions";
import Select from "react-select";
import axios from "../../axios";
import { TranslationElement } from "./components/TranslationElement";
import { Button } from "@material-ui/core";

class index extends Component {
  state = {
    selectedLanguage: null,
    translations: [],
  };
  submitBTNRef = null;
  async getTranslations() {
    console.log("getting translations ...");
    const { handleAppError, setLoading } = this.props;
    try {
      setLoading(true);

      const response = await axios.get("translations/get-all");
      await this.setState({
        translations: [],
      });
      await this.setState({
        translations: response.data.data,
      });
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  }
  componentDidMount() {
    this.submitBTNRef = React.createRef(null);
  }
  componentDidUpdate() {}
  async onSubmit(e) {
    const { handleAppError, showMessage, setLoading } = this.props;
    const { lang } = this.props.user;
    try {
      setLoading(true);
      e.preventDefault();
      const data = new FormData(e.target),
        translations = { ...this.state.translations },
        selectedLanguage = this.state.selectedLanguage;
      for (const [name, value] of data) {
        const id = name.replace("i", "");

        Object.keys(translations).forEach((key) => {
          if (parseInt(translations[key].id) === parseInt(id)) {
            translations[key][selectedLanguage] = value;
          }
        });
      }
      await axios.post("translations/set-by-language", {
        translations: translations,
        lang: selectedLanguage,
      });
      showMessage({
        type: "success",
        title: window.sys_app_translate("Successful Operation", lang),
        message: window.sys_app_translate("Operation successfully done!", lang),
      });
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  }
  render() {
    const { lang } = this.props.user;
    const languages = [
      {
        label: window.sys_app_translate("English", lang),
        value: "en",
      },
      {
        label: window.sys_app_translate("Arabic", lang),
        value: "ar",
      },
      {
        label: window.sys_app_translate("Turkish", lang),
        value: "tr",
      },
      {
        label: window.sys_app_translate("Persian", lang),
        value: "fa",
      },
    ];
    const { selectedLanguage, translations } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{window.sys_app_translate("Translations", lang)}</title>
        </Helmet>
        <div
          className="row justify-content-end"
          style={{ marginBottom: "25.8px", position: "sticky", zIndex: "1" }}
        >
          <div className="col-3  d-flex justify-content-end align-items-center">
            <Select
              className="itqan-react-select-field mx-1 w-100"
              options={languages}
              placeholder={window.sys_app_translate("Select Language", lang)}
              onChange={(e) => {
                this.setState(
                  {
                    selectedLanguage: e.value,
                  },
                  () => {
                    this.getTranslations();
                  }
                );
              }}
            />
            <Button
              variant="contained"
              style={{ display: "inline" }}
              className="table-add-button inter inter-bold"
              onClick={() => {
                if (this.submitBTNRef && this.submitBTNRef.current) {
                  this.submitBTNRef.current.click();
                }
              }}
            >
              {window.sys_app_translate("Save", lang)}
            </Button>
          </div>
        </div>
        <form onSubmit={this.onSubmit.bind(this)}>
          <button
            style={{ display: "none" }}
            ref={this.submitBTNRef}
            type="submit"
          ></button>
          <div className="row">
            {translations &&
              selectedLanguage &&
              translations.map((translation, index) => (
                <TranslationElement
                  key={"tr" + index}
                  id={translation.id}
                  str={translation.str}
                  value={translation[selectedLanguage]}
                />
              ))}
          </div>
        </form>
      </React.Fragment>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors } = appReducer;
  return { user, formErrors };
};
export default connect(mapStateToProps, {
  setCurrentRecord,
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
})(index);
