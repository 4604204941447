import React, { useState, useEffect, useRef } from "react";

import ITQANDropDownItem from "./ITQANDropDownItem";
import { ITQANDropDownArrow } from "../constants";
import _, { drop } from "lodash";
import $ from "jquery";

const ITQANDropDown = ({
  placeholder = "Select Agencies",
  optionsP,
  lang,
  optionsSelected,
  originalSelectedOptions = [],
  fullWidth = false,
  fixedMenuPosition = false,
}) => {
  let firstTimeLoad = true;
  const dropdownReferrer = useRef(null);
  const [options] = useState(optionsP);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(
    originalSelectedOptions
  );
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectParentAndChild, setSelectParentAndChild] = useState(true);
  const [selectChildrenLevel1Only, setSelectChildrenLevel1Only] =
    useState(true);
  const [showOnlySelected, setShowOnlySelected] = useState(true);

  const filterOptionsRecursively = (option) => {
    if (
      option.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (option.children &&
        option.children.some((child) => filterOptionsRecursively(child)))
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    optionsSelected(selectedOptions);
  }, [selectedOptions]);
  useEffect(() => {
    let clickReferrer = document.addEventListener("click", (e) => {
      if (dropdownReferrer && dropdownReferrer.current) {
        let isClick = dropdownReferrer.current.contains(e.target);
        if (!isClick) {
          if (!e.target.classList.contains("dropdown-icon-p")) {
            console.log("hiding dropdown list ...");
            setDropdownVisible(false);
          }
        }
      }
    });
    return () => {
      document.removeEventListener("click", clickReferrer);
    };
  }, []);

  let filteredOptions = _.cloneDeep(optionsP);
  filteredOptions = filteredOptions.filter((option) => {
    console.log("filtering ...");
    if (!searchQuery) {
      if (option) {
        option.children =
          optionsP[0] !== null && optionsP[0].children
            ? [...optionsP[0].children]
            : [];
      }
    } else {
      option.children = option.children.filter((option) =>
        filterOptionsRecursively(option)
      );
    }
    return true;
  });

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const handleParentCheckboxChange = (option) => {
    const newSelectedOptions = [...selectedOptions];
    const toggleOption = (opt, stopSelection = false) => {
      if (selectParentAndChild) {
        if (newSelectedOptions.includes(opt.id)) {
          // Uncheck parent and its children
          newSelectedOptions.splice(newSelectedOptions.indexOf(opt.id), 1);
          opt.children &&
            opt.children.forEach((child) => {
              if (!stopSelection) {
                toggleOption(child, selectChildrenLevel1Only);
              }
            });
        } else {
          // Check parent and its children
          newSelectedOptions.push(opt.id);
          opt.children &&
            opt.children.forEach((child) => {
              if (!stopSelection) {
                toggleOption(child, selectChildrenLevel1Only);
              }
            });
        }
      } else {
        // Only toggle the parent
        if (newSelectedOptions.includes(opt.id)) {
          newSelectedOptions.splice(newSelectedOptions.indexOf(opt.id), 1);
        } else {
          newSelectedOptions.push(opt.id);
        }
      }
    };

    toggleOption(option);
    setSelectedOptions(newSelectedOptions);
  };

  return (
    <div
      ref={dropdownReferrer}
      className={
        fullWidth
          ? "col-12"
          : "col-10 offset-1 col-md-6 offset-md-0 col-lg-8 offset-lg-0"
      }
    >
      <div className="itqan-dropdown-container">
        <div className="position-relative dropdown-container">
          <div className="dropdown-select">
            <div
              className={
                "d-flex align-items-center justify-content-between flex-1 h-100 px-2 dropdown-select-element " +
                (fullWidth ? "dropdown-select-element-full-width" : "")
              }
              onClick={toggleDropdown}
            >
              <span>{window.sys_app_translate(placeholder, lang)}</span>
              <div className="d-flex align-items-center px-2">
                <ITQANDropDownArrow />
              </div>
            </div>

            {isDropdownVisible && (
              <div
                className={
                  "menu-style d-flex flex-column " +
                  (fixedMenuPosition
                    ? "menu-position-fixed"
                    : "menu-position-absolute")
                }
              >
                {fixedMenuPosition && (
                  <span
                    className="menu-close-button"
                    onClick={() => setDropdownVisible(false)}
                  >
                    X
                  </span>
                )}
                <div className="d-flex flex-column">
                  <input
                    value={searchQuery}
                    type="text"
                    className="dropdown-select-search-input px-2 mt-2"
                    placeholder="Type Agency Name"
                    onKeyUp={(e) => {
                      var targetElement = $(
                        '.itqan-dropdown-container *[forname^="' +
                          e.target.value.toLowerCase() +
                          '"]'
                      );
                      if (targetElement.length) {
                        $(".itqan-dropdown-container .menu-items").animate(
                          {
                            scrollTop: targetElement.offset().top,
                          },
                          100
                        );
                      }
                    }}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <label className="d-flex mt-2 text-start">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selectParentAndChild}
                      onChange={() =>
                        setSelectParentAndChild(!selectParentAndChild)
                      }
                    />
                    <span className="ms-2">
                      {window.sys_app_translate(
                        "Select Parent and Child",
                        lang
                      )}
                    </span>
                  </label>
                  <label className="d-flex mt-2 text-start">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selectChildrenLevel1Only}
                      onChange={() =>
                        setSelectChildrenLevel1Only(!selectChildrenLevel1Only)
                      }
                    />
                    <span className="ms-2">
                      {window.sys_app_translate(
                        "Select children level 1 only",
                        lang
                      )}
                    </span>
                  </label>
                </div>
                <div className="menu-items mb-3 mt-1">
                  {filteredOptions.map((option) => {
                    if (searchQuery && option.children.length === 0) {
                      return (
                        option.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) && (
                          <ITQANDropDownItem
                            key={option.id}
                            option={option}
                            searchQuery={searchQuery}
                            selectedOptions={selectedOptions}
                            onCheckboxChange={handleParentCheckboxChange}
                          />
                        )
                      );
                    } else {
                      return (
                        <ITQANDropDownItem
                          key={option.id}
                          option={option}
                          searchQuery={searchQuery}
                          selectedOptions={selectedOptions}
                          onCheckboxChange={handleParentCheckboxChange}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ITQANDropDown;
