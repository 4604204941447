import React, { Component } from "react";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import "../libs/styles/signin.css";
import {
  signinUserIn,
  setLoading,
  showMessage,
  handleAppError,
} from "../actions";
import { ErrorBoundary } from "react-error-boundary";
import { Button } from "@material-ui/core";
import { OfflineStorage } from "../libs/estrylib";

class FixBrowser extends Component {
  state = {
    details: null,
  };
  constructor() {
    super();
  }
  componentDidMount() {}
  errorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>{window.sys_app_translate("Something went wrong", "en")}:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>
          {window.sys_app_translate("Try again", "en")}
        </button>
      </div>
    );
  }
  render() {
    const { details } = this.state;
    return (
      <ErrorBoundary FallbackComponent={this.errorFallback} onReset={() => {}}>
        <div className="container-fluid qr-page" style={{ height: "100vh" }}>
          <div className="row d-flex justify-content-center align-content-center">
            <div className="col-11 col-md-8 col-lg-5 col-xl-4 d-flex justify-content-center">
              <div className="container-fluid">
                <div className="row my-3">
                  <div className="col-12 text-center">
                    <img
                      style={{ height: "10rem" }}
                      src={require("../assets/system-agency-logo-for-qr-insurance-page.png")}
                    />
                  </div>
                </div>
                <div
                  className="row justify-content-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-7 qr-detail-sub-title inter inter-medium text-center">
                        <Button
                          variant="outlined"
                          className="inter inter-bold"
                          style={{ border: "1px solid white" }}
                          color="default"
                          onClick={(e) => {
                            OfflineStorage.instance.clear();
                            window.location.reload();
                          }}
                        >
                          <span style={{ color: "white" }}>
                            {window.sys_app_translate("Fix", "en")}
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { loading } = appReducer;
  return { user, loading };
};

export default connect(mapStateToProps, {
  signinUserIn,
  setLoading,
  showMessage,
  handleAppError,
})(FixBrowser);
