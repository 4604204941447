import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
  setCurrentRecord,
} from "../../actions";
import axios from "../../axios";
import SendMessageComponent from "../message-requests/components/SendMessageComponent";

class index extends Component {
  state = {
    agencies: null,
  };
  constructor() {
    super();
  }
  componentDidMount() {
    this.getRelatedData();
  }
  async getRelatedData() {
    const { setLoading } = this.props;
    setLoading(true);
    await this.getAgenciesForDropdown();
    setLoading(false);
  }
  async getAgenciesForDropdown() {
    const { handleAppError, setLoading } = this.props;
    const { agency_id } = this.props.user;
    try {
      const response = await axios.get("agencies/get-agencies-tree", {
        params: { rootagencyid: agency_id },
      });
      await this.setState({
        agencies: [response.data.data],
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  render() {
    const { lang } = this.props.user;
    const { setLoading, showMessage, handleAppError } = this.props;
    const { agencies } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-5">
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {window.sys_app_translate("Advanced Reports", lang)}
              </title>
            </Helmet>
            <div
              className="container-fluid"
              style={{
                backgroundColor: "white",
                borderRadius: "25px",
                padding: "1rem",
              }}
            >
              {agencies && (
                <SendMessageComponent
                  agencies={agencies}
                  showMessage={showMessage}
                  setLoading={setLoading}
                  handleAppError={handleAppError}
                  lang={lang}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors, currentRecord } = appReducer;
  return { user, formErrors, currentRecord };
};
export default connect(mapStateToProps, {
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
  setCurrentRecord,
})(index);
