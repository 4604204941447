/**
 * App Reducers
 **/
import { combineReducers } from "redux";
import settings from "./settings";
import appReducer from "./AppReducer";
import authUserReducer from "./AuthUserReducer";

const reducers = combineReducers({
  settings,
  authUser: authUserReducer,
  appReducer: appReducer,
});

export default reducers;
