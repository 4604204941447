const academicYears = [
  { label: "2022-2023", value: "2022-2023" },
  { label: "2023-2024", value: "2023-2024" },
  { label: "2024-2025", value: "2024-2025" },
  { label: "2025-2026", value: "2025-2026" },
  { label: "2026-2027", value: "2026-2027" },
  { label: "2027-2028", value: "2027-2028" },
  { label: "2028-2029", value: "2028-2029" },
  { label: "2029-2030", value: "2029-2030" },
  { label: "2030-2031", value: "2030-2031" },
  { label: "2031-2032", value: "2031-2032" },
  { label: "2032-2033", value: "2032-2033" },
  { label: "2033-2034", value: "2033-2034" },
  { label: "2034-2035", value: "2034-2035" },
  { label: "2035-2036", value: "2035-2036" },
  { label: "2036-2037", value: "2036-2037" },
  { label: "2037-2038", value: "2037-2038" },
  { label: "2038-2039", value: "2038-2039" },
  { label: "2039-2040", value: "2039-2040" },
  { label: "2040-2041", value: "2040-2041" },
  { label: "2041-2042", value: "2041-2042" },
  { label: "2042-2043", value: "2042-2043" },
  { label: "2043-2044", value: "2043-2044" },
  { label: "2044-2045", value: "2044-2045" },
  { label: "2045-2046", value: "2045-2046" },
  { label: "2046-2047", value: "2046-2047" },
  { label: "2047-2048", value: "2047-2048" },
  { label: "2048-2049", value: "2048-2049" },
  { label: "2049-2050", value: "2049-2050" },
  { label: "2050-2051", value: "2050-2051" },
  { label: "2051-2052", value: "2051-2052" },
  { label: "2052-2053", value: "2052-2053" },
  { label: "2053-2054", value: "2053-2054" },
  { label: "2054-2055", value: "2054-2055" },
  { label: "2055-2056", value: "2055-2056" },
  { label: "2056-2057", value: "2056-2057" },
  { label: "2057-2058", value: "2057-2058" },
  { label: "2058-2059", value: "2058-2059" },
  { label: "2059-2060", value: "2059-2060" },
];
export default academicYears;
