export default function CheckboxGroup({ settings, onSettingChecked, lang }) {
  return (
    <div className="itqan-checkbox-group-wrp">
      {Object.keys(settings).map((key, index) => {
        return (
          <div
            className="itqan-checkbox-group"
            key={"CheckboxGroupItem" + index}
          >
            <input
              id="records"
              type="checkbox"
              name=""
              onChange={(e) => {
                onSettingChecked(key);
              }}
              checked={settings[key]}
            />
            <label htmlFor="records">
              {window.sys_app_translate(key, lang)}
            </label>
          </div>
        );
      })}
    </div>
  );
}
