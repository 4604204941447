export const AnnouncementMedium = {
  System: 0,
  Whatsapp: 1,
  Sms: 2,
  Mail: 3,
};

export const AnnouncementAudience = {
  Agents: 0,
  Students: 1,
  Employees: 2,
};

export const AnnouncementCategory = {
  University: 0,
  Program: 1,
  General: 2,
};

export const AnnouncementOccurrence = {
  OneTime: 0,
  MultipleTimes: 1,
};

export const AnnouncementPublishDateTime = {
  Now: 0,
  DateTime: 1,
};

export const AnnouncementUrgencyLevel = {
  High: 0,
  Medium: 1,
  Low: 2,
};
