import { OfflineStorage } from "./estrylib";

export const AgencyLoyaltyHigh = "High";
export const AgencyLoyaltyMedium = "Medium";
export const AgencyLoyaltyLow = "Low";
export const AgencyLoyaltyVeryLow = "VeryLow";
export const AgencyLoyalties = [
  {
    label: window.sys_app_translate(
      AgencyLoyaltyHigh,
      OfflineStorage.instance.get("esitqappedu-lang")
    ),
    value: AgencyLoyaltyHigh,
  },
  {
    label: window.sys_app_translate(
      AgencyLoyaltyMedium,
      OfflineStorage.instance.get("esitqappedu-lang")
    ),
    value: AgencyLoyaltyMedium,
  },
  {
    label: window.sys_app_translate(
      AgencyLoyaltyLow,
      OfflineStorage.instance.get("esitqappedu-lang")
    ),
    value: AgencyLoyaltyLow,
  },
  {
    label: window.sys_app_translate(
      AgencyLoyaltyVeryLow,
      OfflineStorage.instance.get("esitqappedu-lang")
    ),
    value: AgencyLoyaltyVeryLow,
  },
];
