import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  InputAdornment,
  LinearProgress,
  Slide,
  TextField,
} from "@material-ui/core";
import {
  LoginPageLogo,
  LoginPageLeftSideLogo,
  SigninPasswordIcon,
  SigninEmailIcon,
} from "../constants";
import "../libs/styles/signin.css";
import {
  signinUserIn,
  setLoading,
  showMessage,
  handleAppError,
} from "../actions";
import { ErrorBoundary } from "react-error-boundary";
import axios from "../axios";
import AppConfig from "../constants/AppConfig";
import {
  ModeChangePassword,
  ModeCheckVerificationCode,
  ModeLogin,
  ModeSendVerificationCode,
} from "../constants/Modes";
import { Slider } from "../components/Slider";

class Signin extends Component {
  state = {
    email: null,
    password: null,
    mode: ModeLogin,
    userId: null,
    verificationCode: null,
    passwordFieldLabel: window.sys_app_translate("Password", "en"),
    passwordFieldPlaceHolder: window.sys_app_translate(
      "Enter your password",
      "en"
    ),
    passwordResetAttempts: 0,
  };
  constructor() {
    super();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  async loginUser() {
    this.props.setLoading(true);
    this.props.signinUserIn(
      {
        email: this.state.email,
        password: this.state.password,
      },
      this.props.history
    );
  }
  async sendPasswordResetRequest() {
    const { handleAppError, showMessage, setLoading } = this.props;
    const { passwordResetAttempts } = this.state;
    if (passwordResetAttempts === 3) {
      showMessage({
        type: "danger",
        title: window.sys_app_translate("Maximum Attempts Reached!", "en"),
        message: window.sys_app_translate(
          "Maximum attempts of 3 reached! Please try again later",
          "en"
        ),
      });
      return;
    }
    console.log("sending password reset request ...");
    try {
      setLoading(true);
      const response = await axios.post("/auth/send-verification-code", {
          userstring: this.state.email,
        }),
        data = response.data.data;
      if (data.smessage) {
        await this.setState({
          userId: data.smessage,
          mode: ModeCheckVerificationCode,
          passwordFieldLabel: window.sys_app_translate(
            "Verification Code",
            "en"
          ),
          passwordFieldPlaceHolder: window.sys_app_translate(
            "Enter received verification code",
            "en"
          ),
          passwordResetAttempts: passwordResetAttempts + 1,
        });
      }
      if (data.emessage) {
        showMessage({
          type: "success",
          title: window.sys_app_translate("Successful Operation!", "en"),
          message: data.emessage,
        });
      }
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  }
  async checkPasswordResetCode() {
    const { handleAppError, showMessage, setLoading } = this.props,
      { userId, verificationCode } = this.state;
    try {
      setLoading(true);
      const response = await axios.post("auth/check-verification-code", {
          userid: userId,
          verificationcode: verificationCode,
        }),
        data = response.data.data;
      if (data.result) {
        this.setState({
          mode: ModeChangePassword,
          passwordFieldLabel: window.sys_app_translate("New Password", "en"),
          passwordFieldPlaceHolder: window.sys_app_translate(
            "Enter new password!",
            "en"
          ),
        });
      } else {
        showMessage({
          type: "danger",
          title: window.sys_app_translate("Wrong Verification Code!", "en"),
          message: window.sys_app_translate(
            "Verification Code is wrong!",
            "en"
          ),
        });
      }
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  }
  async changePassword() {
    const { handleAppError, showMessage, setLoading } = this.props,
      { userId, verificationCode, password } = this.state;
    try {
      setLoading(true);
      const response = await axios.post("auth/change-password", {
          userid: userId,
          verificationcode: verificationCode,
          password: password,
        }),
        data = response.data.data;
      if (data.result) {
        showMessage({
          type: "success",
          title: window.sys_app_translate("Successful Operation!", "en"),
          message: window.sys_app_translate(
            "Password changed successfully!",
            "en"
          ),
        });
        await this.setState({
          mode: ModeLogin,
          passwordFieldLabel: window.sys_app_translate("Password", "en"),
          passwordFieldPlaceHolder: window.sys_app_translate(
            "Enter your password",
            "en"
          ),
        });
      } else {
        showMessage({
          type: "danger",
          title: window.sys_app_translate("Changing Failed!", "en"),
          message: window.sys_app_translate(data.error, "en"),
        });
      }
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  }
  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.loginUser();
    }
  }
  componentDidMount() {
    this.props.setLoading(false);
    document.addEventListener("keydown", this.handleKeyDown);
    this.checkIfToShowSubdomainMessage();
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }
  checkIfToShowSubdomainMessage() {
    const { showMessage } = this.props;
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.os) {
      showMessage({
        type: "warning",
        title: "Login via subdomain",
        message: "You have to login only through: " + params.os,
      });
    }
  }
  errorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>{window.sys_app_translate("Something went wrong", "en")}:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }
  _getLeftSideBackgroundLogo() {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          top: "4rem",
        }}
      >
        <LoginPageLeftSideLogo style={{ width: "80%" }} />
      </div>
    );
  }
  _getLeftSideContent() {
    return (
      <div className="row left-side-layer-1">
        <div className="col-10 offset-1">
          <h2 className="inter inter-bold login-page-section-title-1">
            {window.sys_app_translate("Welcome to ITQAN Education System", "en")}
          </h2>
          <p
            className="inter inter-medium d-none d-sm-block"
            style={{ marginTop: "3.61rem" }}
          >
            {/* {window.sys_app_translate(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut egestas facilisis tincidunt. Maecenas pulvinar ipsumsuscipit libero finibus",
              "en"
            )} */}
          </p>
          <h2
            className="inter inter-bold login-page-section-title-2"
            style={{ marginTop: "30px" }}
          >
            {/* {window.sys_app_translate("Our Expertise", "en")} */}
          </h2>
          <div className="container-fluid" style={{ marginTop: "58.7px" }}>
            <div className="row">
              {/* <div className="col-12 d-flex justify-content-between universities-logos"></div> */}
              <div className="col-12" style={{ position: "relative" }}>
                <Slider />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  _getRightSideContent() {
    const { mode, passwordFieldPlaceHolder } = this.state;
    return (
      <div className="col-sm-6">
        <div className="text-center" style={{ marginBottom: "3rem" }}>
          <LoginPageLogo className="login-page-form-logo" />
        </div>
        <h2
          className="inter inter-regular d-none d-sm-block"
          style={{
            textAlign: "center",
            fontSize: "1.6rem",
            color: AppConfig.themeColors.grey,
          }}
        >
          {mode !== ModeLogin
            ? window.sys_app_translate("Forgot Password", "en")
            : window.sys_app_translate(
                "Welcome to our knowledge base system",
                "en"
              )}
        </h2>
        {mode === ModeLogin && (
          <div
            style={{
              textAlign: "center",
              marginBottom: "5.05rem",
              fontSize: "1.2rem",
              color: AppConfig.themeColors.grey,
            }}
            className="inter inter-light d-none d-sm-block"
          >
            {window.sys_app_translate(
              "Please sign-in to access your account",
              "en"
            )}
          </div>
        )}
        <form action="">
          {(mode === ModeLogin || mode === ModeSendVerificationCode) && (
            <>
              <TextField
                variant="outlined"
                label="Email or Username"
                type="text"
                className="inter inter-semi-bold"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SigninEmailIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                  });
                }}
              />
            </>
          )}
          {(mode === ModeCheckVerificationCode ||
            mode === ModeLogin ||
            mode === ModeChangePassword) && (
            <>
              <TextField
                label={window.sys_app_translate("Password", "en")}
                variant="outlined"
                type={mode !== ModeCheckVerificationCode ? "password" : "text"}
                className="inter inter-semi-bold"
                style={{ marginTop: "2.44rem" }}
                placeholder={passwordFieldPlaceHolder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SigninPasswordIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (mode === ModeCheckVerificationCode) {
                    this.setState({
                      verificationCode: e.target.value,
                    });
                  }
                  if (mode === ModeLogin) {
                    this.setState({
                      password: e.target.value,
                    });
                  }
                }}
              />
            </>
          )}
          {/* <div
        className="d-flex justify-content-between"
        style={{ marginTop: "21px" }}
      >
        <select name="" id="">
    {window.sys_app_translate("select Language", "en")}
    <option value="">AR</option>
  </select>
      </div> */}
          <Button
            variant="contained"
            className="login-button inter inter-bold"
            type="submit"
            style={{
              display: "inline",
              width: "100%",
              fontSize: "1.125rem",
              marginTop: "2.44rem",
              fontWeight: "bold",
            }}
            onClick={(e) => {
              console.log("submitting ...");
              e.preventDefault();
              if (mode === ModeSendVerificationCode) {
                this.sendPasswordResetRequest();
              }
              if (mode === ModeCheckVerificationCode) {
                this.checkPasswordResetCode();
              }
              if (mode === ModeChangePassword) {
                this.changePassword();
              }
              if (mode === ModeLogin) {
                this.loginUser();
              }
            }}
          >
            {window.sys_app_translate(
              mode !== ModeLogin ? "Send" : "Login",
              "en"
            )}
          </Button>
          {mode === ModeLogin && (
            <div style={{ marginTop: "2.44rem" }}>
              <a
                className="inter inter-semi-bold"
                style={{
                  color: "#707070",
                  cursor: "pointer",
                  textDecoration: "none",
                  marginTop: "21px",
                }}
                onClick={() => {
                  this.setState({
                    mode: ModeSendVerificationCode,
                  });
                }}
              >
                {window.sys_app_translate("Forgot Password?", "en")}
              </a>
            </div>
          )}
          {mode === ModeCheckVerificationCode && (
            <div className="mt-2 d-flex justify-content-center">
              <span className="inter inter-semi-bold">
                {window.sys_app_translate("Didn't receive the code?", "en")}
              </span>
              <a
                className="inter inter-semi-bold mx-2"
                style={{
                  color: "#199B8B",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                onClick={() => {
                  this.sendPasswordResetRequest();
                }}
              >
                {window.sys_app_translate("Resend Now", "en")}
              </a>
            </div>
          )}
        </form>
      </div>
    );
  }
  render() {
    const { loading } = this.props;
    return (
      <ErrorBoundary FallbackComponent={this.errorFallback} onReset={() => {}}>
        {loading && (
          <div
            className="container-fluid"
            style={{ position: "fixed", zIndex: "100000" }}
          >
            <LinearProgress color="primary" />
            <LinearProgress color="primary" />
          </div>
        )}
        <div
          className="container-fluid login-page"
          style={{ backgroundColor: "white" }}
        >
          <div className="row">
            <div className="col-5 px-0 d-none d-none d-sm-block">
              <div className="container-fluid" style={{ position: "relative" }}>
                {this._getLeftSideBackgroundLogo()}
                {this._getLeftSideContent()}
              </div>
            </div>
            <div className="col-12 col-sm-7 px-0" style={{ zIndex: "100" }}>
              <div className="container-fluid">
                <div className="row left-side-layer-2 px-0">
                  <div className="col-10 offset-0 col-sm-12 offset-sm-0">
                    <div className="container-fluid">
                      <div className="row d-flex justify-content-center">
                        <div
                          className="d-block d-sm-none"
                          style={{
                            backgroundColor: AppConfig.themeColors.primary,
                            height: "4.38rem",
                            position: "absolute",
                            top: "0",
                          }}
                        ></div>
                        {this._getRightSideContent()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 px-0 d-block d-sm-none left-side-col-div">
              <div className="container-fluid" style={{ position: "relative" }}>
                {this._getLeftSideBackgroundLogo()}
                {this._getLeftSideContent()}
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { loading } = appReducer;
  return { user, loading };
};

export default connect(mapStateToProps, {
  signinUserIn,
  setLoading,
  showMessage,
  handleAppError,
})(Signin);
