import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AppConfig from "../../constants/AppConfig";

import {
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
  setCurrentRecord,
} from "../../actions";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "../../axios";
import { Helmet } from "react-helmet";
import Filters from "./components/Filter";
import { DropdownDataFetcher } from "../../libs";
import JsFileDownloader from "js-file-downloader";
import { AppSettings } from "../../constants";

function Agreement(props) {
  const [items, setItems] = React.useState([]);
  const [agencies, setAgencies] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [params, setParams] = useState({});
  const firstLoadRef = React.useRef(true);
  useEffect(() => {
    const fetchAgencies = async () => {
      const agenciesData = await DropdownDataFetcher.getAgenciesForDropdown(
        props
      );
      setAgencies(agenciesData.get);
    };
    const fetchUniversities = async () => {
      const universitiesData =
        await DropdownDataFetcher.getUniversitiesForDropdown(props);
      setUniversities(universitiesData.get);
    };
    fetchUniversities();
    fetchAgencies();
  }, []);
  useEffect(() => {
    // if (firstLoadRef.current) {
    //   firstLoadRef.current = false;
    //   return;
    // }
    getAgreementItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  const getAgreementItems = async () => {
    try {
      props.setLoading(true);
      const response = await axios.get("/agreement/view", {
        params: params,
      });
      setItems(response.data.data);
      props.setLoading(false);
    } catch (error) {
      props.handleAppError(error);
    }
  };
  const _getBonusSection = (bonus) => {
    return (
      <div className="col-12">
        <table
          className="table text-center agreement-table"
          style={{ minWidth: "100%" }}
        >
          <thead>
            <tr>
              <th
                colSpan={3}
                className="inter inter-bold"
                style={{
                  textAlign: "center",
                  color: AppConfig.themeColors.primary,
                  backgroundColor: "#F3F2F2",
                  fontSize: "1.5rem",
                  verticalAlign: "middle",
                }}
              >
                <Icon
                  icon={"fa6-solid:money-bill-trend-up"}
                  color={AppConfig.themeColors.primary}
                  fontSize={"1.5rem"}
                  className="mx-2"
                />
                {window.sys_app_translate("Bonus", props.user.lang)}
                {": "}
                {window.sys_app_translate("End of Month", props.user.lang)}
              </th>
            </tr>
            <tr>
              <th
                className="inter inter-bold"
                style={{ color: AppConfig.themeColors.primary, width: "33%" }}
              >
                {window.sys_app_translate("Associate", props.user.lang)}
              </th>
              <th
                className="inter inter-bold"
                style={{ color: AppConfig.themeColors.primary, width: "33%" }}
              >
                {window.sys_app_translate("Bachelor", props.user.lang)}
              </th>
              <th
                className="inter inter-bold"
                style={{ color: AppConfig.themeColors.primary, width: "33%" }}
              >
                {window.sys_app_translate("Medical", props.user.lang)}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{bonus.associate}</td>
              <td>{bonus.bachelor}</td>
              <td>{bonus.medical}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  const _getCommissionsSection = (commissions) => {
    return (
      <div className="col-12">
        <table
          className="table text-center agreement-table"
          style={{ minWidth: "100%" }}
        >
          <thead>
            <tr>
              <th
                colSpan={4}
                className="inter inter-bold"
                style={{
                  textAlign: "center",
                  color: AppConfig.themeColors.primary,
                  fontSize: "1.5rem",
                  backgroundColor: "#F3F2F2",
                }}
              >
                <Icon
                  icon={"fa6-solid:hand-holding-dollar"}
                  color={AppConfig.themeColors.primary}
                  fontSize={"1.5rem"}
                  className="mx-2"
                />
                {window.sys_app_translate("Commissions", props.user.lang)}
              </th>
            </tr>
            <tr>
              {commissions[0].language === "nolanguage" ? null : (
                <th
                  className="inter inter-bold"
                  style={{ color: AppConfig.themeColors.primary }}
                >
                  {window.sys_app_translate("Language", props.user.lang)}
                </th>
              )}
              <th
                className="inter inter-bold"
                style={{ color: AppConfig.themeColors.primary }}
              >
                {window.sys_app_translate("Degree", props.user.lang)}
              </th>
              <th
                className="inter inter-bold"
                style={{ color: AppConfig.themeColors.primary }}
              >
                {window.sys_app_translate("Faculty", props.user.lang)}
              </th>
              <th
                className="inter inter-bold"
                style={{ color: AppConfig.themeColors.primary }}
              >
                {window.sys_app_translate("Commission", props.user.lang)}
              </th>
            </tr>
          </thead>
          <tbody>
            {commissions.map((commission, index) => {
              return (
                <tr>
                  {commission.language === "nolanguage" ? null : (
                    <td
                      className="inter inter-bold"
                      style={{ color: "#686868", width: "25%" }}
                    >
                      {commission.language}
                    </td>
                  )}
                  <td
                    className="inter inter-medium"
                    style={{
                      color: "#686868",
                      width:
                        commission.language === "nolanguage" ? "33%" : "25%",
                    }}
                  >
                    {commission.degree}
                  </td>
                  <td
                    className="inter inter-medium"
                    style={{
                      color: "#686868",
                      width:
                        commission.language === "nolanguage" ? "33%" : "25%",
                    }}
                  >
                    {commission.faculty}
                  </td>
                  <td
                    className="inter inter-medium"
                    style={{
                      color: "#686868",
                      width:
                        commission.language === "nolanguage" ? "33%" : "25%",
                    }}
                  >
                    {commission.commission}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{window.sys_app_translate("Agreement", props.user.lang)}</title>
      </Helmet>
      <Filters
        isRootAgency={props.user.is_root_agency}
        props={props}
        agencies={agencies}
        universities={universities}
        doFilter={(filters) => {
          setParams((prevState) => ({
            ...prevState,
            filters: filters,
          }));
        }}
        exportToPDF={async () => {
          try {
            props.setLoading(true);
            console.log("params ...", params);
            const paramsCopy = { ...params };
            paramsCopy.exporttopdf = true;
            const response = await axios.get("/agreement/view", {
              params: paramsCopy,
            });
            const fileName = response.data.data;
            const fileLink =
              AppConfig.storagePath + "generated-pdfs/" + fileName;
            console.log("fileLink ...", fileLink);
            new JsFileDownloader({
              url: fileLink,
            });
            props.setLoading(false);
          } catch (error) {
            props.handleAppError(error);
          }
        }}
      />
      {items.map((item, index) => {
        return (
          <div className="row mb-4">
            <div className="container-fluid">
              <div
                className="row py-3"
                style={{
                  backgroundColor: AppConfig.themeColors.primary,
                  color: "white",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                }}
              >
                <div className="col-8 d-flex justify-content-start align-items-center">
                  <Icon
                    icon={"fa6-solid:file-contract"}
                    color={"white"}
                    fontSize={"1.8rem"}
                    className="mx-2"
                  />
                  <span
                    className="inter inter-bold"
                    style={{ fontSize: "1.5rem" }}
                  >
                    {window.sys_app_translate("Agreement", props.user.lang)}
                  </span>
                </div>
                <div className="col-4 d-flex justify-content-end">
                  {props.user.is_root_agency && (
                    <Icon
                      icon={"material-symbols:contract-edit-rounded"}
                      color={"white"}
                      fontSize={"2rem"}
                      className="mx-2"
                      role="button"
                      onClick={() => {
                        let agencyId = props.user.agency_id;
                        if (params.filters && params.filters.agencyid) {
                          agencyId = params.filters.agencyid;
                        }
                        if (agencyId === AppSettings.SystemAgencyID) {
                          props.setCurrentRecord(agencyId);
                        } else {
                          props.setCurrentRecord({
                            agencyid: agencyId,
                            step: 3,
                          });
                        }

                        props.history.push("agencies/modify");
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row py-4" style={{ backgroundColor: "white" }}>
                <div className="col-2">
                  <img
                    style={{ width: "100%" }}
                    src={
                      "https://itqanapply.com/storage/" + item.universitylogo
                    }
                  />
                </div>
                <div className="col-10">
                  <h1
                    className="inter inter-bold"
                    style={{ color: "#686868", fontSize: "2rem" }}
                  >
                    {item.universityname}
                  </h1>
                  <h1
                    className="inter inter-bold"
                    style={{
                      color: AppConfig.themeColors.primary,
                      fontSize: "1.5rem",
                    }}
                  >
                    {window.sys_app_translate(
                      "Agreement Information",
                      props.user.lang
                    )}
                  </h1>
                  <p
                    className="inter inter-medium"
                    style={{ color: "#686868", fontSize: "1.5rem" }}
                  >
                    {item.educationtax &&
                      `${window.sys_app_translate("Education Tax")}: ${
                        item.educationtax
                      }%`}
                    <br />
                    {`${window.sys_app_translate("KDV")}:  ${item.tax}%`}
                    <br />
                    {params.filters &&
                      params.filters.agencyid &&
                      params.filters.agencyid === AppSettings.SystemAgencyID &&
                      `${window.sys_app_translate("Supplier")}: ${
                        item.suppliername
                      }`}
                  </p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  backgroundColor: "white",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
              >
                {item.bonus && _getBonusSection(item.bonus)}
                {item.commissions && _getCommissionsSection(item.commissions)}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

// map state to props
const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors, currentRecord } = appReducer;
  return { user, formErrors, currentRecord };
};
export default connect(mapStateToProps, {
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
  setCurrentRecord,
})(Agreement);
