import { useEffect, useRef, useState } from "react";
//import { IoIosArrowDown } from "react-icons/io";
import {
  TurkishLanguageIcon,
  ArabicLanguageIcon,
  EnglishLanguageIcon,
  GlobalLanguageIcon,
  LanguageSelectorDownArrow,
  PersianLanguageIcon,
} from "../constants";
import OfflineStorage from "./estrylib/OfflineStorage";
import axios from "../axios";

export default function LanguageSelector({ lang, handleAppError, setLoading }) {
  const btn = useRef();
  const item = useRef();

  const [isActive, setIsActive] = useState(false);
  const [btnInfo, setBtnInfo] = useState({
    img: <GlobalLanguageIcon />,
    name: OfflineStorage.instance.get("esitqappedu-lang").toUpperCase(),
  });

  const [items, setItem] = useState([
    {
      img: <ArabicLanguageIcon />,
      value: "AR",
      name: window.sys_app_translate("AR", lang),
    },
    {
      img: <EnglishLanguageIcon />,
      value: "EN",
      name: window.sys_app_translate("EN", lang),
    },
    {
      img: <TurkishLanguageIcon />,
      value: "TR",
      name: window.sys_app_translate("TR", lang),
    },
    {
      img: <PersianLanguageIcon />,
      value: "FA",
      name: window.sys_app_translate("FA", lang),
    },
  ]);

  const activeHandler = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };

  const selectHandler = async (e) => {
    try {
      setLoading(true);
      await axios.post("/users/set-user-language", {
        lang: e.value.toLowerCase(),
      });
      OfflineStorage.instance.set("esitqappedu-lang", e.value.toLowerCase());
      setBtnInfo(e);
      setIsActive(false);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      handleAppError(error);
    }
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      try {
        if (!isActive) {
          let isClick = item.current ? item.current.contains(e.target) : false,
            isBtn = btn.current.contains(e.target);

          if (!isClick && !isBtn) {
            setIsActive(false);
          }
        }
      } catch (error) {
        //do nothing
      }
    });
  }, []);

  return (
    <div className="selector-item-container">
      <div className="selector">
        <div ref={btn} onClick={activeHandler} className="selector-btn">
          {btnInfo.img}
          <span>{window.sys_app_translate(btnInfo.name, lang)}</span>
          <span className="icon">
            <LanguageSelectorDownArrow />
          </span>
        </div>
        <ul
          ref={item}
          className={`selector-item-wrp ${(isActive && "active") || ""}`}
        >
          {items.map((item, i) => (
            <li
              onClick={(e) => selectHandler(item)}
              className="selector-item"
              key={i}
            >
              {item.img}
              <span>{item.name}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
