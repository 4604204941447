export const AppCurrenciesForDropdown = [
  {
    label: "$",
    value: "USD",
  },
  {
    label: "€",
    value: "EUR",
  },
];
