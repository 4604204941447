/**
 * App Config File
 */
const AppConfig = {
  serverLink: window.location.origin,
  apiLink:
    process.env.NODE_ENV === "production"
      ? "https://" + window.location.host + "/itqanapi"
      : "https://itqan-education-system-laravel.test/itqanapi",
  storagePath:
    process.env.NODE_ENV === "production"
      ? "/storage/"
      : "https://itqan-education-system-laravel.test/storage/",
  baseRoute: "eee", // App Logo
  navCollapsed: false, // Sidebar collapse
  darkMode: false, // Dark Mode
  boxLayout: false, // Box Layout
  rtlLayout: false, // RTL Layout
  miniSidebar: false, // Mini Sidebar
  enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
  isDarkSidenav: true, // Set true to dark sidebar
  enableThemeOptions: true, // Enable Theme Options
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "en",
  },
  themeColors: {
    primary: "#21827f",
    secondary: "#ED4C16",
    success: "#3CCB00",
    danger: "#FF3739",
    warning: "#FFB70F",
    info: "#00D0BD",
    dark: "#464D69",
    default: "#FAFAFA",
    greyLighten: "#A5A7B2",
    grey: "#686868",
    white: "#FFFFFF",
    purple: "#896BD6",
    yellow: "#D46B08",
  },
  darkThemeColors: {
    darkBgColor: "#424242",
  },
  pageTableSize: 10,
};

export default AppConfig;
