import SecureLS from 'secure-ls';

const OfflineStorage = {
    _instance: null,
    get instance() {
        if(!this._instance){
            this._instance = new SecureLS();
        }
        return this._instance;
    }
};
export default OfflineStorage;