import React, { useEffect, useState } from "react";
import Select from "react-select";
import OfflineStorage from "../estrylib/OfflineStorage";
export const commaFormatted = (amount, withDecimals = false) => {
  amount = parseFloat(amount).toFixed(2);
  var delimiter = ","; // replace comma if desired
  var a = amount.split(".", 2);
  var d = a[1];
  var i = parseInt(a[0]);
  if (isNaN(i)) {
    return "";
  }
  var minus = "";
  if (i < 0) {
    minus = "-";
  }
  i = Math.abs(i);
  var n = new String(i);
  var a = [];
  while (n.length > 3) {
    var nn = n.substr(n.length - 3);
    a.unshift(nn);
    n = n.substr(0, n.length - 3);
  }
  if (n.length > 0) {
    a.unshift(n);
  }
  n = a.join(delimiter);
  if (d.length < 1) {
    amount = n;
  } else {
    amount = n + "." + d;
  }
  if (!withDecimals) {
    amount = amount.split(".")[0];
  }
  amount = minus + amount;
  return amount;
};
// export const SelectColumnFilter = ({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }) => {
//   const options = React.useMemo(() => {
//     const options = new Set();
//     preFilteredRows.forEach((row) => {
//       options.add(row.values[id]);
//     });
//     return [...options.values()];
//   }, [id, preFilteredRows]);
//   // Render a multi-select box
//   return (
//     <select
//       value={filterValue}
//       onChange={(e) => {
//         setFilter(e.target.value || undefined);
//       }}
//     >
//       <option value="">All</option>
//       {options.map((option, i) => (
//         <option key={i} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// };
export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [state, setState] = useState({
    options: [],
    originalOptions: [],
  });
  const options = React.useMemo(() => {
    const options = [];
    const insertedOptions = {};
    preFilteredRows.forEach((row) => {
      if (
        !insertedOptions["i" + row.values[id]] &&
        row.values[id] &&
        row.values[id] !== "-"
      ) {
        insertedOptions["i" + row.values[id]] = true;
        options.push({
          label: row.values[id],
          value: row.values[id],
        });
      }
    });
    return options;
  }, [id, preFilteredRows]);
  let firstTime = [];
  useEffect(() => {
    if (firstTime) {
      setState({
        ...state,
        options: options,
        originalOptions: options,
      });
    }
    return () => {};
  }, []);
  let columnName = null;
  switch (id) {
    case "parentagency":
      columnName = window.sys_app_translate(
        "Parent Agency",
        OfflineStorage.instance.get("esitqappedu-lang")
      );
      break;
    case "created_at":
      columnName = window.sys_app_translate(
        "Creation Date",
        OfflineStorage.instance.get("esitqappedu-lang")
      );
      break;
    case "active":
      columnName = window.sys_app_translate(
        "Active",
        OfflineStorage.instance.get("esitqappedu-lang")
      );
      break;
    case "agency":
      columnName = window.sys_app_translate(
        "Agency",
        OfflineStorage.instance.get("esitqappedu-lang")
      );
      break;
    case "role":
      columnName = window.sys_app_translate(
        "Role",
        OfflineStorage.instance.get("esitqappedu-lang")
      );
      break;
    case "status":
      columnName = window.sys_app_translate(
        "Status",
        OfflineStorage.instance.get("esitqappedu-lang")
      );
      break;
    case "activeForTable":
      columnName = window.sys_app_translate(
        "Status",
        OfflineStorage.instance.get("esitqappedu-lang")
      );
      break;
    case "createdby":
      columnName = window.sys_app_translate(
        "Created By",
        OfflineStorage.instance.get("esitqappedu-lang")
      );
      break;
    case "isvalid":
      columnName = window.sys_app_translate(
        "Only Valid?",
        OfflineStorage.instance.get("esitqappedu-lang")
      );
      break;
  }
  const customSelectFilterStyles = {
    menu: (provided) => ({
      ...provided,
      width: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      width: "auto",
    }),
  };
  // Render a multi-select box
  return (
    <div style={{ marginLeft: "5px", marginRight: "5px" }}>
      <Select
        menuPlacement="auto"
        className="itqan-react-select-field"
        classNamePrefix="itqan-react-select-field"
        options={state.options}
        placeholder={columnName}
        menuPortalTarget={document.body}
        styles={customSelectFilterStyles}
        onChange={(e) => {
          setFilter(e.value || undefined);
        }}
      />
    </div>
  );
};
