import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
  setCurrentRecord,
} from "../../actions";
import axios from "../../axios";
import { OptionsComponent } from "./components/OptionsComponent";
import { ITQModal } from "../../libs";
import { Button } from "@material-ui/core";
import { TableButtonEditIcon, TableRejectIcon } from "../../constants/SVGIcons";

class Options extends Component {
  state = {
    currentRecord: null,
  };
  constructor() {
    super();
  }
  componentDidMount() {}
  async getOptionsData() {
    const { handleAppError, setLoading } = this.props;
    try {
      setLoading(true);
      const response = await axios.get("/options");
      const rawOptions = response.data.data;
      const bankAccount = JSON.parse(rawOptions[0].value)[0];
      const companyIPSetting = JSON.parse(rawOptions[1].value);
      const options = {};
      options.bankaccountname = bankAccount.name;
      options.iban = bankAccount.iban;
      options.bankname = bankAccount.bank;
      options.enablecompanyip = companyIPSetting.enabled;
      options.companyip = companyIPSetting.ip;
      options.apiautoretriescount = rawOptions[5].value;
      options.trafficnetpercentage = rawOptions[6].value;
      options.systemautomode = JSON.parse(rawOptions[2].value);
      await this.setState({
        currentRecord: options,
      });
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  }
  async submit(optionsRecord) {
    const { setLoading, handleAppError, showMessage } = this.props;
    const { lang } = this.props.user;
    try {
      setLoading(true);
      const optionsRecordToPost = {
        bank_accounts: [
          {
            name: optionsRecord.bankaccountname,
            iban: optionsRecord.iban,
            bank: optionsRecord.bankname,
          },
        ],
        company_ip: {
          enabled: optionsRecord.enablecompanyip,
          ip: optionsRecord.companyip,
        },
        system_mode_auto: optionsRecord.systemautomode,
        apiautoretriescount: optionsRecord.apiautoretriescount,
        trafficnetpercentage: optionsRecord.trafficnetpercentage,
      };
      await axios.post("options/update", {
        options: JSON.stringify(optionsRecordToPost),
      });
      showMessage({
        type: "success",
        title: window.sys_app_translate("Operation Succeeded", lang),
        message: window.sys_app_translate(
          "Options successfully updated!",
          lang
        ),
      });
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  }
  render() {
    const { lang, permissions } = this.props.user;
    const { currentRecord } = this.state;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{window.sys_app_translate("Settings", lang)}</title>
        </Helmet>
        {currentRecord && (
          <OptionsComponent
            permissions={permissions}
            optionsRecord={currentRecord}
            submitRecord={this.submit.bind(this)}
          />
        )}
      </>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors, currentRecord } = appReducer;
  return { user, formErrors, currentRecord };
};
export default connect(mapStateToProps, {
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
  setCurrentRecord,
})(Options);
