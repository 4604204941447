import React from "react";
import { BootstrapTable } from "./estrylib";
import {
  RetryIcon,
  TableResetFiltersButtonIcon,
  TableResetFiltersButtonIconRed,
  TableRetryAllInsuranceRequestsIcon,
} from "../constants";
import ContextMenu from "./ContextMenu";
import AppConfig from "../constants/AppConfig";

export const PageTable = React.memo(
  ({
    columns,
    data,
    fetchData,
    pagesCount,
    hiddenColumns,
    isRemoteFetch = false,
    isRemoteSearch = false,
    additionalClassName = "",
    lang = "en",
    additionalIconClick = null,
    setEFilters = null,
    setESorters = null,
    resetEFilters = null,
    filters = null,
    toggleTableColumnShow = null,
    showContextMenuIcon = true,
    pageSize = null,
    savePageSize = null,
  }) => {
    return (
      <div
        className={"row page-content " + additionalClassName}
        style={{ position: "relative" }}
      >
        <div className="col-12" style={{ position: "relative" }}>
          {isRemoteFetch && (
            <a
              className=" table-reload-icon"
              onClick={() => {
                fetchData();
              }}
            >
              <RetryIcon />
            </a>
          )}
          {isRemoteFetch && resetEFilters && (
            <a
              className="table-additional-icon"
              onClick={() => {
                resetEFilters();
              }}
            >
              {filters && <TableResetFiltersButtonIconRed />}
              {!filters && <TableResetFiltersButtonIcon />}
            </a>
          )}
          {showContextMenuIcon && (
            <ContextMenu
              items={columns}
              menuId={"test1"}
              handleItemClick={toggleTableColumnShow}
            />
          )}
          {additionalIconClick && (
            <a
              className="table-additional-icon3"
              onClick={() => {
                additionalIconClick();
              }}
            >
              <TableRetryAllInsuranceRequestsIcon />
            </a>
          )}
          {isRemoteFetch ? (
            <BootstrapTable
              loading={false}
              columns={columns}
              data={data}
              fetchData={fetchData}
              isRemoteFetch={isRemoteFetch}
              pageCount={pagesCount}
              hiddenColumns={hiddenColumns}
              isRemoteSearch={isRemoteSearch}
              lang={lang}
              setEFilters={setEFilters}
              setESorters={setESorters}
              filters={filters}
              initialPageSize={pageSize ?? AppConfig.pageTableSize}
              savePageSize={savePageSize}
            />
          ) : (
            <BootstrapTable
              loading={false}
              columns={columns}
              data={data}
              isRemoteFetch={isRemoteFetch}
              hiddenColumns={hiddenColumns}
              isRemoteSearch={isRemoteSearch}
              lang={lang}
              initialPageSize={pageSize ?? AppConfig.pageTableSize}
              savePageSize={savePageSize}
              filters={filters}
            />
          )}
        </div>
      </div>
    );
  }
);
