import { OfflineStorage } from "../libs/estrylib";

export const PaymentMethodCash = "Cash";
export const PaymentMethodBank = "BankTransfer";
export const PaymentMethodCreditCard = "BankCard";

export const PaymentMethodsForSelect = [
  {
    label: window.sys_app_translate(
      PaymentMethodCash,
      OfflineStorage.instance.get("esitqappedu-lang")
    ),
    value: PaymentMethodCash,
  },
  {
    label: window.sys_app_translate(
      PaymentMethodBank,
      OfflineStorage.instance.get("esitqappedu-lang")
    ),
    value: PaymentMethodBank,
  },
  {
    label: window.sys_app_translate(
      PaymentMethodCreditCard,
      OfflineStorage.instance.get("esitqappedu-lang")
    ),
    value: PaymentMethodCreditCard,
  },
];
