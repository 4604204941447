import { OfflineStorage } from "./estrylib";

export const Today = window.sys_app_translate("Today", "en");
export const Yesterday = window.sys_app_translate("Yesterday", "en");
export const Last7Days = window.sys_app_translate("Last 7 Days", "en");
export const Last30Days = window.sys_app_translate("Last 30 Days", "en");
export const ThisMonth = window.sys_app_translate("This Month", "en");
export const ThisYear = window.sys_app_translate("This Year", "en");
export const LastMonth = window.sys_app_translate("Last Month", "en");
export const CustomRange = window.sys_app_translate("Custom Range", "en");
