import Home from "../routes/home";
import Agencies from "../routes/agencies";
import Roles from "../routes/roles";
import Users from "../routes/users";
import Translations from "../routes/translations";

import DeveloperTools from "../routes/developer-tools";
import Options from "../routes/options";
import Messaging from "../routes/messaging";
import MessageRequests from "../routes/message-requests";

import Announcements from "../routes/announcements";
import Suppliers from "../routes/suppliers";
import Expenses from "../routes/expenses";
import Universities from "../routes/universities";
import Applications from "../routes/applications";
import AgreementProfits from "../routes/agreement-profits";
import ReleasedProfits from "../routes/released-profits";
import Students from "../routes/students";
import Notifications from "../routes/notifications";
import Specialization from "../routes/specializations";
import Agreement from "../routes/agreement";

export default [
  {
    path: "home",
    component: Home,
  },
  {
    path: "expenses",
    component: Expenses,
  },
  {
    path: "agencies",
    component: Agencies,
  },
  {
    path: "roles",
    component: Roles,
  },
  {
    path: "users",
    component: Users,
  },
  {
    path: "announcements",
    component: Announcements,
  },
  {
    path: "notifications",
    component: Notifications,
  },
  {
    path: "suppliers",
    component: Suppliers,
  },
  {
    path: "specializations",
    component: Specialization,
  },
  {
    path: "universities",
    component: Universities,
  },
  {
    path: "applications",
    component: Applications,
  },
  {
    path: "agreement-profits",
    component: AgreementProfits,
  },
  {
    path: "released-profits",
    component: ReleasedProfits,
  },
  {
    path: "students",
    component: Students,
  },
  {
    path: "translations",
    component: Translations,
  },
  {
    path: "developer-tools",
    component: DeveloperTools,
  },
  {
    path: "options",
    component: Options,
  },
  {
    path: "messaging",
    component: Messaging,
  },
  {
    path: "message-requests",
    component: MessageRequests,
  },
  {
    path: "agreement",
    component: Agreement,
  },
];
