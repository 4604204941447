import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  MenuItemBalanceRequestsIcon,
  MenuItemHomeIcon,
  MenuItemSettingsIcon,
  MenuItemReportsIcon,
  MenuItemPaperReqesutsIcon,
  MenuItemInsurancesIcon,
  MenuItemUsersIcon,
  MenuItemInsuranceCompaniesIcon,
  MenuItemAgenciesIcon,
  MenuItemTranslationsIcon,
  MenuItemProfitsIcon,
  MenuItemReceivedInsurancesIcon,
  MenuItemAnnouncementsIcon,
  MenuItemLeadsIcon,
  MenuItemMessageRequestsIcon,
  MenuItemResidenceAppointments,
  MenuItemStudentsIcon,
  MenuItemApplicationsIcon,
  MenuItemAgreementProfitsIcon,
  MenuItemSpecializationsIcon,
  MenuItemUniversitiesIcon,
  MenuItemSuppliersIcon,
  MenuItemAgreementsIcon,
} from "../../../constants";
import { MdKeyboardArrowDown } from "react-icons/md";
import $ from "jquery";
import AppConfig from "../../../constants/AppConfig";
import { Icon } from "@iconify/react/dist/iconify.js";
export default function Menu({ menuItems, handler, logout }) {
  const getIcon = (iconTitle) => {
    let iconELement = null;
    switch (iconTitle) {
      case "home":
        iconELement = <MenuItemHomeIcon />;
        break;
      case "insurances":
        iconELement = <MenuItemInsurancesIcon />;
        break;
      case "received-insurances":
        iconELement = <MenuItemReceivedInsurancesIcon />;
        break;
      case "balance-requests":
        iconELement = <MenuItemBalanceRequestsIcon />;
        break;
      case "paper-requests":
        iconELement = <MenuItemPaperReqesutsIcon />;
        break;
      case "reports":
        iconELement = <MenuItemReportsIcon />;
        break;
      case "users":
        iconELement = <MenuItemUsersIcon />;
        break;
      case "settings":
        iconELement = <MenuItemSettingsIcon />;
        break;
      case "insurance-companies":
        iconELement = <MenuItemInsuranceCompaniesIcon />;
        break;
      case "deadlines":
        iconELement = <Icon icon="ion:calendar" fontSize={"1.4rem"} />;
        break;
      case "profits":
        // iconELement = <MenuItemProfitsIcon />;
        iconELement = (
          <Icon
            icon={"fa6-solid:sack-dollar"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "financial-requests":
        iconELement = <MenuItemInsurancesIcon />;
        break;
      case "agencies":
        iconELement = <MenuItemAgenciesIcon />;
        break;
      case "translations":
        iconELement = <MenuItemTranslationsIcon />;
        break;
      case "expenses":
        iconELement = <MenuItemInsurancesIcon />;
        break;
      case "announcements":
        iconELement = (
          <Icon
            icon={"fa6-solid:bullhorn"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "leads":
        iconELement = <MenuItemLeadsIcon />;
        break;
      case "message-requests":
        iconELement = <MenuItemMessageRequestsIcon />;
        break;
      case "residence-appointments":
        iconELement = <MenuItemResidenceAppointments />;
        break;
      case "students":
        // iconELement = <MenuItemStudentsIcon />;
        iconELement = (
          <Icon
            icon={"fa6-solid:user-graduate"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "applications":
        iconELement = <MenuItemApplicationsIcon />;
        break;
      case "agreement-profits":
        iconELement = (
          <Icon
            icon={"fa6-solid:sack-dollar"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "specializations":
        iconELement = (
          <Icon
            icon={"fa6-solid:file-contract"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "Agreement":
        iconELement = (
          <Icon
            icon={"fa6-solid:book-open-reader"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "universities":
        iconELement = (
          <Icon
            icon={"bi:building-fill"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "suppliers":
        iconELement = <MenuItemSuppliersIcon />;
        break;
      case "agreements":
        iconELement = <MenuItemAgreementsIcon />;
        break;
    }
    return iconELement;
  };
  useEffect(() => {
    $(".nav-item a").removeClass("active");
    return () => {};
  }, []);
  return (
    <>
      <ul className="nav">
        {menuItems.map((menuItem, index) => {
          if (!menuItem) {
            return null;
          }
          if (!menuItem.subMenu) {
            return (
              <li
                className="nav-item"
                key={"MenuItem" + index}
                onClick={() => {
                  if (menuItem.path === "/logout") {
                    logout();
                  }
                }}
              >
                {menuItem.path === "/logout" ? (
                  <>
                    <a
                      onClick={() => {
                        logout();
                      }}
                      className="logout-btn"
                    >
                      <Icon
                        icon={"ic:baseline-logout"}
                        color={"white"}
                        fontSize={"1.33rem"}
                        role="button"
                      />
                      {getIcon(menuItem.icon)}
                      <span className="title">{menuItem.title}</span>
                    </a>
                  </>
                ) : (
                  <>
                    <NavLink
                      id={"MenuItemN" + index}
                      to={"/app" + menuItem.path}
                    >
                      {getIcon(menuItem.icon)}
                      <span className="title">{menuItem.title}</span>
                    </NavLink>
                  </>
                )}
              </li>
            );
          } else {
            return (
              <li
                className="nav-item menu-item-n"
                key={"MenuItem" + index}
                id={"MenuItemC" + index}
              >
                <NavLink
                  id={"MenuItemN" + index}
                  to={menuItem.path}
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.innerWidth >= 576) {
                      window.burgerButtonClicked(true);
                    }
                    $(".menu-item-n .angle")
                      .not("#MenuItemC" + index + " .angle")
                      .removeClass("active");
                    $(".menu-item-n .sub-menu")
                      .not("#MenuItemC" + index + " .sub-menu")
                      .removeClass("show");
                    //toggle submenu
                    $("#MenuItemN" + index + " .angle").toggleClass("active");
                    $(".sub-menu" + index).toggleClass("show");
                  }}
                >
                  {getIcon(menuItem.icon)}
                  <span className="title">{menuItem.title}</span>
                  <span className="angle">
                    <MdKeyboardArrowDown />
                  </span>
                </NavLink>
                {/* submenu */}
                <ul className={"sub-menu sub-menu" + index}>
                  {menuItem.subMenu.map((submenuItem, index) => {
                    if (!submenuItem) {
                      return null;
                    }
                    return (
                      <li className="nav-item" key={"SubMenuItem" + index}>
                        <NavLink
                          id={"SubMenuItemN" + index}
                          to={"/app" + submenuItem.path}
                          onClick={() => {
                            $(".sidebar-container").removeClass(
                              "sidebar-container-show"
                            );
                            $(window).unbind("click");
                          }}
                        >
                          <span className="title">{submenuItem.title}</span>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
        })}
      </ul>
    </>
  );
}
