/**
 * Specializations
 */
/* eslint-disable */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// async components
import {
  AsyncManageSpecializationsComponent,
  AsyncAddSpecializationComponent,
  AsyncModifySpecializationComponent,
} from "../../components/AsyncComponent/AsyncComponent";

const Forms = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/manage`} />
      <Route
        path={`${match.url}/view-all`}
        component={AsyncManageSpecializationsComponent}
      />
      <Route
        path={`${match.url}/create`}
        component={AsyncAddSpecializationComponent}
      />
      <Route
        path={`${match.url}/modify`}
        component={AsyncModifySpecializationComponent}
      />
    </Switch>
  </React.Fragment>
);

export default Forms;
