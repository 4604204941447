import React, { useState } from "react";

const TabsComponent = ({ tabs, activeTabName }) => {
  console.log("loading tabs component");
  const [activeTab, setActiveTab] = useState(activeTabName);
  return (
    <div className="row">
      <div className="col-3" style={{ paddingTop: "0.5rem" }}>
        <div
          className="nav flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          {tabs.map((tab) => (
            <a
              key={tab.title}
              className={
                activeTab === tab.title
                  ? "nav-link active inter inter-medium"
                  : "nav-link inter inter-medium"
              }
              id={`v-pills-${tab.title.replace(" ", "")}-tab`}
              data-toggle="pill"
              href={`#v-pills-${tab.title}`}
              role="tab"
              aria-controls={`v-pills-${tab.title}`}
              onClick={() => {
                setActiveTab(tab.title);
              }}
            >
              {tab.title}
            </a>
          ))}
        </div>
      </div>
      <div className="col-9">
        <div className="tab-content" id="v-pills-tabContent">
          {tabs.map((tab) => (
            <div
              key={tab.title}
              className={
                activeTab === tab.title
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id={`v-pills-${tab.title}`}
              role="tabpanel"
              aria-labelledby={`v-pills-${tab.title}-tab`}
            >
              <h3 className="inter inter-medium">{tab.title}</h3>
              {tab.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;
