/**
 * Applications
 */
/* eslint-disable */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// async components
import {
  AsyncManageApplicationsComponent,
  AsyncAddApplicationComponent,
  AsyncModifyApplicationComponent,
  AsyncViewApplicationComponent,
  AsyncViewDeadlinesComponent,
} from "../../components/AsyncComponent/AsyncComponent";

const Forms = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/manage`} />
      <Route
        path={`${match.url}/view-all`}
        component={AsyncManageApplicationsComponent}
      />
      <Route
        path={`${match.url}/view`}
        component={AsyncViewApplicationComponent}
      />
      <Route
        path={`${match.url}/create`}
        component={AsyncAddApplicationComponent}
      />
      <Route
        path={`${match.url}/modify`}
        component={AsyncModifyApplicationComponent}
      />
      <Route
        path={`${match.url}/deadlines`}
        component={AsyncViewDeadlinesComponent}
      />
    </Switch>
  </React.Fragment>
);

export default Forms;
