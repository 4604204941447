import axios from "../../axios";
import React, { Component } from "react";

class index extends Component {
  state = {
    log: null,
  };
  componentDidMount() {
    this.downloadLogFile();
  }
  async downloadLogFile() {
    console.log("getting developer tools ...");
    const response = await axios.get("developer-tools/read-log-file");
    await this.setState({
      log: response.data.data,
    });
  }
  render() {
    return (
      this.state.log && (
        <>
          <div>
            <button
              type="button"
              onClick={async () => {
                await axios.post("developer-tools/empty-log-file");
                window.location.reload();
              }}
            >
              Empty Log File
            </button>
          </div>
          <div>
            <b>Current Crons Count:</b>
            {this.state.log.currentcronscount}
          </div>
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <ul
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "15px",
                listStyle: "none",
              }}
            >
              {this.state.log.file.map((item) => {
                return <li style={{ marginBottom: "15px" }}>{item}</li>;
              })}
            </ul>
          </div>
        </>
      )
    );
  }
}

export default index;
