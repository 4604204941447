/**
 *  App Actions
 */
import {
  SET_CURRENT_RECORD,
  SET_LOADING,
  SET_MESSAGE,
  SET_CONFIRMATION,
  SET_ERROR,
  SET_FORM_ERRORS,
  HANDLE_APP_ERROR,
  SET_DRAFT_INDICATOR,
  SET_AGENCY_PERCENTAGE,
  SET_AGENCY_PROFIT,
  SET_AGENCY_INFO,
} from "./types";

export const setCurrentRecord = (record) => ({
  type: SET_CURRENT_RECORD,
  payload: record,
});
export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});
export const setAgencyPercentage = (percentage) => ({
  type: SET_AGENCY_PERCENTAGE,
  payload: percentage,
});
export const setAgencyProfit = (profit) => ({
  type: SET_AGENCY_PROFIT,
  payload: profit,
});
export const setAgencyInfo = (agencyInfo) => ({
  type: SET_AGENCY_INFO,
  payload: agencyInfo,
});
export const showMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message,
});
export const showConfirmation = (confirmation) => ({
  type: SET_CONFIRMATION,
  payload: confirmation,
});
export const showFormErrors = (errorObject) => ({
  type: SET_FORM_ERRORS,
  payload: errorObject,
});
export const showError = (error) => ({
  type: SET_ERROR,
  payload: error,
});
export const clearFormErrors = () => ({
  type: SET_FORM_ERRORS,
  payload: null,
});
export const handleAppError = (appError) => ({
  type: HANDLE_APP_ERROR,
  payload: appError,
});
export const setDraftAvailability = (draftAvailability) => ({
  type: SET_DRAFT_INDICATOR,
  payload: draftAvailability,
});
