/**
 * Insurance Agencies Routes
 */
/* eslint-disable */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// async components
import {
  AsyncManageAgenciesComponent,
  AsyncAddAgencyComponent,
  AsyncModifyAgencyComponent,
} from "../../components/AsyncComponent/AsyncComponent";

const Forms = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/manage`} />
      <Route
        path={`${match.url}/view-all`}
        component={AsyncManageAgenciesComponent}
      />
      <Route path={`${match.url}/create`} component={AsyncAddAgencyComponent} />
      <Route
        path={`${match.url}/modify`}
        component={AsyncModifyAgencyComponent}
      />
    </Switch>
  </React.Fragment>
);

export default Forms;
