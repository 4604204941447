import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { TableMenuIcon } from "../constants";

export default function ContextMenu({ menuId, items, handleItemClick }) {
  const { show } = useContextMenu({
    id: menuId,
  });
  function displayMenu(e) {
    show({
      event: e,
    });
  }
  return (
    <div className="table-additional-icon2">
      <div onClick={displayMenu}>
        <TableMenuIcon />
      </div>
      <Menu id={menuId}>
        {items.map((item) => {
          if (item.neverShow) {
            return null;
          }
          return (
            <Item
              key={item.accessor + "contextMenu"}
              onClick={() => {
                handleItemClick(item.accessor);
              }}
            >
              <span className="mx-1" style={{ color: "#199b8b" }}>
                {item.show !== false ? "✓" : null}
              </span>
              {item.Header}
            </Item>
          );
        })}
      </Menu>
    </div>
  );
}
