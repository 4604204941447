/**
 * Errors Sagas
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import ErrorsHandler from "../libs/estrylib/ErrorsHandler";
import { HANDLE_APP_ERROR } from "../actions/types";
import {
  logoutUser,
  showError,
  showMessage,
  setLoading,
  showFormErrors,
} from "../actions";

function* handleAppError({ payload }) {
  const result = yield call(ErrorsHandler.handleAppError, payload);
  yield put(setLoading(false));
  if (result.isFormErrors) {
    console.log(result.message);
    yield put(
      showMessage({
        message: result.message,
        title: result.title,
        type: result.type,
      })
    );
    yield put(showFormErrors(result.errors));
    return;
  }
  if (result.fixed || result.logout) {
    if (result.logout) {
      //yield put(logoutUser());
    } else {
      yield put(
        showError({
          show: true,
          title: result.title,
          message: result.message,
        })
      );
    }
  } else {
    yield put(
      showMessage({
        message: result.message,
        title: result.title,
        type: result.type,
      })
    );
    yield put(setLoading(false));
  }
}

/**
 *
 */
export function* catchAppError() {
  yield takeEvery(HANDLE_APP_ERROR, handleAppError);
}
/**
 * Errors Root Saga
 */
export default function* rootSaga() {
  yield all([fork(catchAppError)]);
}
