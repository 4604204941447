export const ContractType = {
  Standard: "Standard",
  Specific: "Specific",
};
export const ContractTypesForDropdown = [
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Specific",
    value: "Specific",
  },
];
