export const UniversitySector = {
  Public: "Public",
  Private: "Private",
};
export const UniversitySectorForDropdown = [
  {
    label: "Public",
    value: "Public",
  },
  {
    label: "Private",
    value: "Private",
  },
];
