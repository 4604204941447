export const High = "High";
export const Low = "Low";

export const AnouncementPriorities = [
  {
    id: High,
    icon: <></>,
    text: High,
  },
  {
    id: Low,
    icon: <></>,
    text: Low,
  },
];
