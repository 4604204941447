import { set } from "lodash";
import axios from "../axios";
import GeneralHelper from "./GeneralHelper";
import { AgreementBasedOn } from "../constants";
import { OfflineStorage } from "./estrylib";
import { param } from "jquery";
class DropdownDataFetcher {
  static async getRepresentativesForDropdown(props) {
    return await this.getListForDropdown("System Agency Users", props, false, {
      params: { systemagencyonly: true },
    });
  }
  static async getBasedOnForDropdown(props) {
    return {
      get: [
        {
          label: window.sys_app_translate(
            "Based on " + AgreementBasedOn.Specialization,
            props.user.lang
          ),
          value: AgreementBasedOn.Specialization,
        },
        {
          label: window.sys_app_translate(
            "Based on " + AgreementBasedOn.Degree,
            props.user.lang
          ),
          value: AgreementBasedOn.Degree,
        },
      ],
      set: {
        ["item" + AgreementBasedOn.Specialization]: {
          label: window.sys_app_translate(
            "Based on " + AgreementBasedOn.Specialization,
            props.user.lang
          ),
          value: AgreementBasedOn.Specialization,
        },
        ["item" + AgreementBasedOn.Degree]: {
          label: window.sys_app_translate(
            "Based on " + AgreementBasedOn.Degree,
            props.user.lang
          ),
          value: AgreementBasedOn.Degree,
        },
      },
    };
  }
  static async getCommissionDueDatetimeForDropdown(props) {
    return {
      get: [
        {
          label: window.sys_app_translate("Cash", props.user.lang),
          value: "Cash",
        },
        {
          label: window.sys_app_translate("End Of Season", props.user.lang),
          value: "EndOfSeason",
        },
      ],
      set: {
        itemcash: {
          label: window.sys_app_translate("Cash", props.user.lang),
          value: "Cash",
        },
        itemofseason: {
          label: window.sys_app_translate("End Of Season", props.user.lang),
          value: "EndOfSeason",
        },
      },
    };
  }
  static async getAgenciesTreeData(props) {
    console.log("getting agencies tree ...");
    const { handleAppError, setLoading } = props;
    const { agency_id } = props.user;
    try {
      setLoading(true);
      const response = await axios.get("agencies/get-agencies-tree", {
        params: { rootagencyid: agency_id },
      });
      setLoading(false);
      return [response.data.data];
    } catch (error) {
      handleAppError(error);
    }
  }
  static async getShiftTypesForDropdown(props) {
    return await this.getListForDropdown("Shifttypes", props, false);
  }
  static async getSemestersForDropdown(props) {
    return await this.getListForDropdown("Semesters", props, false);
  }
  static async getStudentsForDropdown(props) {
    return await this.getListForDropdown("Students", props, false);
  }
  static async getCountriesForDropdown(props) {
    return await this.getListForDropdown("Countries", props, false);
  }
  static async getAgenciesForDropdown(props) {
    return await this.getListForDropdown("Agencies", props, false);
  }
  static async getUniversityCountriesForDropdown(props) {
    return await this.getListForDropdown("UniversityCountries", props, false, {
      params: {
        onlyparent: false,
      },
    });
  }
  static async getUniversityCitiesForDropdown(props) {
    return await this.getListForDropdown("UniversityCities", props, false);
  }
  static async getSuppliersForDropdown(props) {
    return await this.getListForDropdown("Suppliers", props, false);
  }
  static async getSpecializationsForDropdown(props, universityId) {
    return await this.getListForDropdown("Specializations", props, false, {
      params: {
        universityid: universityId,
      },
    });
  }
  static async getSpecializationsFieldsForDropdown(props, universityId) {
    return await this.getListForDropdown(
      "SpecializationsFields",
      props,
      false,
      {
        params: {
          universityid: universityId,
        },
      }
    );
  }
  static async getUniversitiesFacultiesForDropdown(props, forContract = false) {
    return await this.getListForDropdown("Faculties", props, false, {
      params: {
        forcontract: forContract,
      },
    });
  }
  static async getUniversitiesForDropdown(props) {
    return await this.getListForDropdown("Universities", props, true);
  }
  static async getUniversitiesForApplication(props) {
    return await this.getListForDropdown(
      "UniversitiesForApplication",
      props,
      true
    );
  }
  static async getFieldsForDropdown(props) {
    return await this.getListForDropdown("Fields", props, false);
  }
  static async getDegreesForDropdown(props, includeThesis = false) {
    return await this.getListForDropdown("Degrees", props, true, {
      params: {
        includethesis: includeThesis,
      },
    });
  }
  static async getStudyLanguagesForDropdown(props) {
    return await this.getListForDropdown("StudyLanguages", props, true);
  }
  static async getAgreementTypesForDropdown(props) {
    return await this.getListForDropdown("AgreementTypes", props, true);
  }
  static async getRejectReasonsForDropdown(props) {
    return await this.getListForDropdown("RejectReasons", props, true);
  }
  static async getWhatsappGroupsForDropdown(props) {
    return await this.getListForDropdown("WhatsappGroups", props, false);
  }
  static async getListForDropdown(
    apiListType,
    props,
    translateLabels = false,
    params = {},
    showLoadingBar = false
  ) {
    console.log("getting list " + apiListType + " for dropdown ...");
    const { setLoading, handleAppError } = props;
    const { lang } = props.user;
    try {
      if (showLoadingBar) {
        setLoading(true);
      }
      let apiEndPoint = null;
      const offlineStorageKey = "ees" + apiListType + props.user.lang;
      let useOfflineStorage = false;
      switch (apiListType) {
        case "Agencies":
          apiEndPoint = "agencies/get-for-dropdown";
          break;
        case "Students":
          apiEndPoint = "students/get-for-dropdown";
          break;
        case "UniversityCountries":
        case "Countries":
          useOfflineStorage = true;
          if (OfflineStorage.instance.get(offlineStorageKey)) {
            console.log(`returning ${apiListType} from offline storage ...`);
            return OfflineStorage.instance.get(offlineStorageKey);
          }
          apiEndPoint = "universities/get-countries-for-dropdown";
          break;
        case "UniversityCities":
          useOfflineStorage = true;
          if (OfflineStorage.instance.get(offlineStorageKey)) {
            console.log(`returning ${apiListType} from offline storage ...`);
            return OfflineStorage.instance.get(offlineStorageKey);
          }
          apiEndPoint = "universities/get-cities-for-dropdown";
          break;
        case "Suppliers":
          apiEndPoint = "suppliers/get-for-dropdown";
          break;
        case "Universities":
          apiEndPoint = "universities/get-for-dropdown";
          break;
        case "UniversitiesForApplication":
          // useOfflineStorage = true;
          // if (OfflineStorage.instance.get(offlineStorageKey)) {
          //   console.log(`returning ${apiListType} from offline storage ...`);
          //   return OfflineStorage.instance.get(offlineStorageKey);
          // }
          apiEndPoint = "universities/get-for-application";
          break;
        case "Fields":
          apiEndPoint = "universities/get-fields-for-dropdown";
          break;
        case "Degrees":
          // useOfflineStorage = false;
          // if (OfflineStorage.instance.get(offlineStorageKey)) {
          //   console.log(`returning ${apiListType} from offline storage ...`);
          //   return OfflineStorage.instance.get(offlineStorageKey);
          // }
          apiEndPoint = "universities/get-degrees-for-dropdown";
          break;
        case "StudyLanguages":
          useOfflineStorage = true;
          if (OfflineStorage.instance.get(offlineStorageKey)) {
            console.log(`returning ${apiListType} from offline storage ...`);
            return OfflineStorage.instance.get(offlineStorageKey);
          }
          apiEndPoint = "universities/get-study-languages-for-dropdown";
          break;
        case "AgreementTypes":
          apiEndPoint = "agencies/get-agreement-types";
          break;
        case "Semesters":
          useOfflineStorage = true;
          if (OfflineStorage.instance.get(offlineStorageKey)) {
            console.log(`returning ${apiListType} from offline storage ...`);
            return OfflineStorage.instance.get(offlineStorageKey);
          }
          apiEndPoint = "universities/get-semesters-for-dropdown";
          break;
        case "Shifttypes":
          useOfflineStorage = true;
          if (OfflineStorage.instance.get(offlineStorageKey)) {
            console.log(`returning ${apiListType} from offline storage ...`);
            return OfflineStorage.instance.get(offlineStorageKey);
          }
          apiEndPoint = "universities/get-shift-types-for-dropdown";
          break;
        case "Specializations":
          apiEndPoint = "specializations/get-for-dropdown";
          break;
        case "SpecializationsFields":
          apiEndPoint = "specializations/fields-for-dropdown";
          break;
        case "Faculties":
          apiEndPoint = "universities/faculties-for-dropdown";
          break;
        case "System Agency Users":
        case "Users":
          apiEndPoint = "users/get-for-dropdown";
          break;
        case "RejectReasons":
          apiEndPoint = "applications/get-reasons-for-dropdown";
          break;
        case "WhatsappGroups":
          apiEndPoint = "agencies/get-whatsapp-groups-for-dropdown";
          break;
      }
      if (!apiEndPoint) {
        return [];
      }
      const response = await axios.get(apiEndPoint, params),
        getAndSetData = GeneralHelper.getGetANDSetDataForDropdownFromResponse(
          response,
          lang,
          translateLabels
        );
      if (showLoadingBar) {
        setLoading(false);
      }
      const data = { get: getAndSetData.get, set: getAndSetData.set };
      if (useOfflineStorage) {
        OfflineStorage.instance.set(offlineStorageKey, data);
      }
      return data;
    } catch (error) {
      console.log(error);
      handleAppError(error);
    }
  }
}
export default DropdownDataFetcher;
