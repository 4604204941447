import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ModalCloseButtonIcon, ModalCloseIcon } from "../constants";
import AppConfig from "../constants/AppConfig";

export const ITQModal = ({
  isOpen,
  title,
  bodyContent,
  cancelButtonClicked,
  buttonsModal = false,
  isCenterd = false,
  size = "lg",
}) => {
  return (
    <React.Fragment>
      <Modal
        className={"modal-" + size + " itqan-modal"}
        isOpen={isOpen}
        backdrop="true"
        centered={isCenterd ? true : false}
        onClosed={() => {}}
      >
        <ModalBody>
          <div className="container">
            <div
              className="row"
              style={{
                backgroundColor: AppConfig.themeColors.primary,
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                minHeight: "3rem",
              }}
            >
              <div className="col-2"></div>
              <div
                className="col-8 inter inter-bold d-flex align-items-center justify-content-center"
                style={{
                  fontSize: "1.125rem",
                  color: "white",
                  zIndex: "0",
                }}
              >
                {title}
              </div>
              <div className="col-2 d-flex justify-content-end align-items-center">
                <ModalCloseIcon
                  className="clickable"
                  onClick={cancelButtonClicked}
                />
              </div>
            </div>
            <div
              className="row"
              style={{
                minHeight: "200px",
                backgroundColor: "white",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              <div className="col-12 py-3">{bodyContent}</div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
