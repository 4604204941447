export const FileInputAllowedTypes = {
  JPEG: "image/jpeg",
  PDF: "application/pdf",
  PNG: "image/png",
};

export const ImageFileTypes = [
  FileInputAllowedTypes.JPEG,
  FileInputAllowedTypes.PNG,
];
