export const UISectionAgencies = "agencies";
export const UISectionExpenses = "expenses";
export const UISectionUsers = "users";
export const UISectionUserRoles = "user-roles";
export const UISectionUniversities = "universities";
export const UISectionSpecializations = "specializations";
export const UISectionAnnouncements = "announcements";
export const UISectionSuppliers = "suppliers";
export const UISectionApplications = "applications";
export const UISectionAgreements = "agreements";
export const UISectionAgreementProfits = "agreement-profits";
export const UISectionReleasedProfits = "released-profits";
export const UISectionStudents = "students";
export const UISectionWithdrawals = "withdrawals";
