import React from "react";
import SliderC from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavigateLeftButtonIcon } from "../constants";

export const Slider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slides = [
    {
      img: require("../assets/universities-logos/yeniyuzyil.png"),
      alt: "1",
    },
    {
      img: require("../assets/universities-logos/aydin.png"),
      alt: "2",
    },
    {
      img: require("../assets/universities-logos/gelisim.png"),
      alt: "4",
    },
    {
      img: require("../assets/universities-logos/istinye.png"),
      alt: "7",
    },
    {
      img: require("../assets/universities-logos/medipol.png"),
      alt: "8",
    },
    {
      img: require("../assets/universities-logos/bau.png"),
      alt: "9",
    },
  ];
  return (
    <>
      <div className="slider-background"></div>
      <SliderC {...settings}>
        {slides.map((slide, index) => {
          return (
            <div key={"slider" + index}>
              <img src={slide.img} alt="1" style={{ height: "6.9rem" }} />
            </div>
          );
        })}
      </SliderC>
    </>
  );
};
