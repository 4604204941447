export const AcademicStatus = {
  Direct: "Direct",
  Transfer: "Transfer",
};
export const AcademicStatusChoices = [
  {
    id: 1,
    icon: <></>,
    text: AcademicStatus.Direct,
  },
  {
    id: 2,
    icon: <></>,
    text: AcademicStatus.Transfer,
  },
];
export const AcademicStatusForDropdown = [
  { value: AcademicStatus.Direct, label: AcademicStatus.Direct },
  { value: AcademicStatus.Transfer, label: AcademicStatus.Transfer },
];
