/**
 * App Redux Action Types
 */
export const COLLAPSED_SIDEBAR = "COLLAPSED_SIDEBAR";
export const DARK_MODE = "DARK_MODE";
export const BOXED_LAYOUT = "BOXED_LAYOUT";
export const RTL_LAYOUT = "RTL_LAYOUT";
export const MINI_SIDEBAR = "MINI_SIDEBAR";
export const SEARCH_FORM_ENABLE = "SEARCH_FORM_ENABLE";
export const CHANGE_THEME_COLOR = "CHANGE_THEME_COLOR";
export const TOGGLE_SIDEBAR_IMAGE = "TOGGLE_SIDEBAR_IMAGE";
export const SET_SIDEBAR_IMAGE = "SET_SIDEBAR_IMAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const START_USER_TOUR = "START_USER_TOUR";
export const STOP_USER_TOUR = "STOP_USER_TOUR";
export const TOGGLE_DARK_SIDENAV = "TOGGLE_DARK_SIDENAV";
// Sidebar
export const TOGGLE_MENU = "TOGGLE_MENU";
// Auth Actions
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";
//App Actions: General
export const SET_AGENCY_BALANCE = "SET_AGENCY_BALANCE";
export const SET_AGENCY_PERCENTAGE = "SET_AGENCY_PERCENTAGE";
export const SET_AGENCY_PROFIT = "SET_AGENCY_PROFIT";
export const SET_AGENCY_INFO = "SET_AGENCY_INFO";
export const SET_CURRENT_RECORD = "SET_CURRENT_RECORD";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_ERROR = "SET_ERROR";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
export const SET_LOADING = "SET_LOADING";
export const SET_CONFIRMATION = "SET_CONFIRMATION";
export const HANDLE_APP_ERROR = "HANDLE_APP_ERROR";
export const SET_DRAFT_INDICATOR = "SET_DRAFT_AVAILABILITY";
export const SET_USER_UI_STATE_DATA = "SET_USER_UI_STATE_DATA";
export const SET_USER_UI_STATE_SECTION_COLUMNS =
  "SET_USER_UI_STATE_SECTION_COLUMNS";
