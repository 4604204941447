import { OfflineStorage } from "../libs/estrylib";
export const ApplicationStatus = {
  // AdmissionStatus: "AdmissionStatus",
  UnderAdmissionApply: "UnderAdmissionApply",
  AdmissionApplied: "AdmissionApplied",
  AcceptanceIssuedWaitingPayment: "AcceptanceIssuedWaitingPayment",
  StudentPaidSendReceipt: "StudentPaidSendReceipt",
  // ReceiptSent: "ReceiptSent",
  FinalAcceptanceSent: "FinalAcceptanceSent",
  // CompleteRegistration: "CompleteRegistration",
  // VisaProcess: "VisaProcess",
  Rejected: "Rejected",
  // RegisteredByAnotherAgency: "RegisteredByAnotherAgency",
  // PaidToAnotherUniversity: "PaidToAnotherUniversity",
  // MissingFromUniversity: "MissingFromUniversity",
  MissingDocuments: "MissingDocuments",
  // SendMissingDocumentsToUniversity: "SendMissingDocumentsToUniversity",
  // RegistrationFailed: "RegistrationFailed",
  RegistrationDone: "RegistrationDone",
  // RegistrationDoneWithoutProcess: "RegistrationDoneWithoutProcess",
  // PaymentReceiptRejected: "PaymentReceiptRejected",
  // ReceiptSentWaitingReceiptConfirmation:
  //   "ReceiptSentWaitingReceiptConfirmation",
  // FinalPaymentUploaded: "FinalPaymentUploaded",
  // Refund: "Refund",
  // AdmissionToUniversityPending: "AdmissionToUniversityPending",
  // QuoteFull: "QuoteFull",
  StudentDocUploaded: "StudentDocUploaded",
  MissingDocumentsUploaded: "MissingDocumentsUploaded",
  Cancelled: "Cancelled",
};
export const ApplicationStatusArray = Object.keys(ApplicationStatus).map(
  (key) => ({
    label: window.sys_app_translate(
      ApplicationStatus[key],
      OfflineStorage.instance.get("esitqappedu-lang")
    ),
    value: key,
  })
);
