export const Gender = {
  Male: "Male",
  Female: "Female",
};

export const GenderChoices = [
  {
    id: 1,
    icon: <></>,
    text: Gender.Male,
  },
  {
    id: 2,
    icon: <></>,
    text: Gender.Female,
  },
];

export const GendersForDropdown = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
];
