/**
 * App Routes
 */
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
// app default layout
import RctAppLayout from "../components/RctAppLayout";
// router service
import routerService from "../services/_routerService";

class DefaultLayout extends Component {
  render() {
    const { match } = this.props;
    return (
      <RctAppLayout>
        {routerService &&
          routerService.map((route, key) => {
            return (
              <Route
                key={key}
                path={`${match.url}/${route.path}`}
                component={route.component}
              />
            );
          })}
      </RctAppLayout>
    );
  }
}

const mapStateToProps = ({ appReducer }) => {
  return { appReducer };
};
export default withRouter(connect(mapStateToProps)(DefaultLayout));
