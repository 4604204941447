import { OfflineStorage } from "../libs/estrylib";
/**
 * Auth User Reducers
 */
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  SET_AGENCY_BALANCE,
  SET_AGENCY_INFO,
  SET_AGENCY_PERCENTAGE,
  SET_AGENCY_PROFIT,
  SET_USER_UI_STATE_DATA,
  SET_USER_UI_STATE_SECTION_COLUMNS,
} from "../actions/types";
import { OfflineStorageKeyAllowAutoRefresh } from "../libs";

/**
 * initial auth user
 */
const INIT_STATE = {
  user: {
    name: OfflineStorage.instance.get("esitqappedu-name"),
    email: OfflineStorage.instance.get("esitqappedu-email"),
    permissions: OfflineStorage.instance.get("esitqappedu-permissions"),
    profile_picture: OfflineStorage.instance.get("esitqappedu-profile-picture"),
    root_agency_logo: OfflineStorage.instance.get(
      "esitqappedu-root-agency-logo"
    ),
    agency_logo: OfflineStorage.instance.get("esitqappedu-agency-logo"),
    lang: OfflineStorage.instance.get("esitqappedu-lang"),
    access_token: OfflineStorage.instance.get("esitqappedu-access-token"),
    subdomain: OfflineStorage.instance.get("esitqappedu-subdomain"),
    server_date: OfflineStorage.instance.get("esitqappedu-server-date"),
    default_permissions: OfflineStorage.instance.get(
      "esitqappedu-default-permissions"
    ),
    is_root_agency: OfflineStorage.instance.get("esitqappedu-is-root-agency"),
    user_id: OfflineStorage.instance.get("esitqappedu-user-id"),
    agency_id: OfflineStorage.instance.get("esitqappedu-agency-id"),
    agency_profit: OfflineStorage.instance.get("esitqappedu-agency-profit"),
    agency_profit2: OfflineStorage.instance.get("esitqappedu-agency-profit-2"),
    top_parent_agency_id: OfflineStorage.instance.get(
      "esitqappedu-top-parent-agency-id"
    ),
    system_currency: OfflineStorage.instance.get("esitqappedu-system-currency"),
    system_currency2: OfflineStorage.instance.get(
      "esitqappedu-system-currency2"
    ),
    max_inactivity_duration: OfflineStorage.instance.get(
      "esitqappedu-max-inactivity-duration"
    ),
    live_chat_id: OfflineStorage.instance.get("esitqappedu-livechat-id"),
    is_super_admin: OfflineStorage.instance.get("esitqappedu-is-super-admin"),
    can_see_super_admin_dashboard: OfflineStorage.instance.get(
      "esitqappedu-can-see-super-admin-dashboard"
    ),
    ui_state_data: OfflineStorage.instance.get("esitqappedu-uistatedata"),
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state };
    case LOGIN_USER_SUCCESS:
      const user = action.payload;
      user.access_token = user.token_type + " " + user.access_token;
      OfflineStorage.instance.set(OfflineStorageKeyAllowAutoRefresh, true);
      OfflineStorage.instance.set("esitqappedu-name", user.name);
      OfflineStorage.instance.set("esitqappedu-email", user.email);
      OfflineStorage.instance.set("esitqappedu-permissions", user.permissions);
      OfflineStorage.instance.set(
        "esitqappedu-profile-picture",
        user.profile_picture
      );
      OfflineStorage.instance.set("esitqappedu-agency-logo", user.agency_logo);
      OfflineStorage.instance.set(
        "esitqappedu-root-agency-logo",
        user.root_agency_logo
      );
      OfflineStorage.instance.set("esitqappedu-lang", user.lang);
      OfflineStorage.instance.set(
        "esitqappedu-access-token",
        user.access_token
      );
      OfflineStorage.instance.set("esitqappedu-server-date", user.server_date);
      OfflineStorage.instance.set("esitqappedu-subdomain", user.subdomain);
      OfflineStorage.instance.set(
        "esitqappedu-default-permissions",
        user.default_permissions
      );
      OfflineStorage.instance.set(
        "esitqappedu-is-root-agency",
        user.is_root_agency
      );
      OfflineStorage.instance.set("esitqappedu-user-id", user.user_id);
      OfflineStorage.instance.set("esitqappedu-agency-id", user.agency_id);
      OfflineStorage.instance.set(
        "esitqappedu-top-parent-agency-id",
        user.top_parent_agency_id
      );
      OfflineStorage.instance.set(
        "esitqappedu-agency-profit",
        user.agency_profit
      );
      OfflineStorage.instance.set(
        "esitqappedu-agency-profit-2",
        user.agency_profit2
      );
      OfflineStorage.instance.set(
        "esitqappedu-system-currency",
        user.system_currency
      );
      OfflineStorage.instance.set(
        "esitqappedu-system-currency-2",
        user.system_currency2
      );
      OfflineStorage.instance.set(
        "esitqappedu-max-inactivity-duration",
        user.max_inactivity_duration
      );
      OfflineStorage.instance.set("esitqappedu-livechat-id", user.live_chat_id);
      OfflineStorage.instance.set(
        "esitqappedu-is-super-admin",
        user.is_super_admin
      );
      OfflineStorage.instance.set(
        "esitqappedu-can-see-super-admin-dashboard",
        user.can_see_super_admin_dashboard
      );
      OfflineStorage.instance.set(
        "esitqappedu-uistatedata",
        user.ui_state_data
      );
      return { ...state, user: user };
    case LOGOUT_USER:
      return { ...state };
    case LOGOUT_USER_SUCCESS:
      OfflineStorage.instance.removeAll();
      return { ...state, user: null };
    case SET_AGENCY_BALANCE:
      const balance = action.payload,
        userCopy = { ...state.user };
      userCopy.agency_balance = balance;
      OfflineStorage.instance.set(
        "esitqappedu-agency-balance",
        userCopy.agency_balance
      );
      return { ...state, user: userCopy };
    case SET_AGENCY_PROFIT:
      const profit = action.payload,
        userCopy4 = { ...state.user };
      userCopy4.agency_profit = profit;
      OfflineStorage.instance.set(
        "esitqappedu-agency-profit",
        userCopy4.agency_profit
      );
      return { ...state, user: userCopy4 };
    case SET_AGENCY_INFO:
      const agencyInfo = action.payload,
        userCopy5 = { ...state.user };
      userCopy5.agency_profit = agencyInfo.profit;
      userCopy5.agency_profit2 = agencyInfo.profit2;
      OfflineStorage.instance.set(
        "esitqappedu-agency-profit",
        userCopy5.agency_profit
      );
      OfflineStorage.instance.set(
        "esitqappedu-agency-profit-2",
        userCopy5.agency_profit2
      );

      return { ...state, user: userCopy5 };
    case SET_USER_UI_STATE_DATA:
      console.log("setting auth reducer ui state data ...");
      const uiData = action.payload,
        userCopyy = { ...state.user };
      userCopyy.ui_state_data = uiData;
      OfflineStorage.instance.set(
        "esitqappedu-uistatedata",
        userCopyy.ui_state_data
      );
      return { ...state, user: userCopyy };
    case SET_USER_UI_STATE_SECTION_COLUMNS:
      console.log("setting auth reducer ui state data ...");

      const userCopyyY = { ...state.user };
      userCopyyY.ui_state_data[action.payload.section]["columns"] =
        action.payload.columns;
      //set localstorage
      OfflineStorage.instance.set(
        "esitqappedu-uistatedata",
        userCopyyY.ui_state_data
      );
      //return state
      return { ...state, user: userCopyyY };
    default:
      return { ...state };
  }
};
