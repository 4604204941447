import { utils, writeFile } from "xlsx";
class ExcelFileHandler {
  crefregex =
    /(^|[^._A-Z0-9])([$]?)([A-Z]{1,2}|[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D])([$]?)([1-9]\d{0,5}|10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6])(?![_.\(A-Za-z0-9])/g;
  autoFitColumns(worksheet) {
    const [startLetter, endLetter] = worksheet["!ref"]
      ?.replace(/\d/, "")
      .split(":");
    let numRegexp = new RegExp(/\d+$/g);
    let start = startLetter.charCodeAt(0),
      end = endLetter.charCodeAt(0) + 1,
      rows = +numRegexp.exec(endLetter)[0];
    let ranges = [];
    for (let i = start; i < end; i++) {
      ranges.push(i);
    }
    let objectMaxLength = [];
    ranges.forEach((c) => {
      const cell = String.fromCharCode(c);
      let maxCellLength = 0;
      for (let y = 1; y <= rows; y++) {
        let cellLength = worksheet[`${cell}${y}`].v
          ? worksheet[`${cell}${y}`].v.length + 1
          : 0;
        if (cellLength > maxCellLength) {
          maxCellLength = cellLength;
        }
      }
      objectMaxLength.push({ width: maxCellLength });
    });
    worksheet["!cols"] = objectMaxLength;
  }
  clamp_range(range) {
    if (range.e.r >= 1 << 20) range.e.r = (1 << 20) - 1;
    if (range.e.c >= 1 << 14) range.e.c = (1 << 14) - 1;
    return range;
  }
  delete_cols(ws, start_col, ncols) {
    if (!ws) throw new Error("operation expects a worksheet");
    var dense = Array.isArray(ws);
    if (!ncols) ncols = 1;
    if (!start_col) start_col = 0;

    /* extract original range */
    var range = utils.decode_range(ws["!ref"]);
    var R = 0,
      C = 0;

    var formula_cb = function ($0, $1, $2, $3, $4, $5) {
      var _R = utils.decode_row($5),
        _C = utils.decode_col($3);
      if (_C >= start_col) {
        _C -= ncols;
        if (_C < start_col) return "#REF!";
      }
      return (
        $1 +
        ($2 == "$" ? $2 + $3 : utils.encode_col(_C)) +
        ($4 == "$" ? $4 + $5 : utils.encode_row(_R))
      );
    };

    var addr, naddr;
    for (C = start_col + ncols; C <= range.e.c; ++C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        addr = utils.encode_cell({ r: R, c: C });
        naddr = utils.encode_cell({ r: R, c: C - ncols });
        if (!ws[addr]) {
          delete ws[naddr];
          continue;
        }
        if (ws[addr].f)
          ws[addr].f = ws[addr].f.replace(this.crefregex, formula_cb);
        ws[naddr] = ws[addr];
      }
    }
    for (C = range.e.c; C > range.e.c - ncols; --C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        addr = utils.encode_cell({ r: R, c: C });
        delete ws[addr];
      }
    }
    for (C = 0; C < start_col; ++C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        addr = utils.encode_cell({ r: R, c: C });
        if (ws[addr] && ws[addr].f)
          ws[addr].f = ws[addr].f.replace(this.crefregex, formula_cb);
      }
    }

    /* write new range */
    range.e.c -= ncols;
    if (range.e.c < range.s.c) range.e.c = range.s.c;
    ws["!ref"] = utils.encode_range(this.clamp_range(range));

    /* merge cells */
    if (ws["!merges"])
      ws["!merges"].forEach(function (merge, idx) {
        var mergerange;
        switch (typeof merge) {
          case "string":
            mergerange = utils.decode_range(merge);
            break;
          case "object":
            mergerange = merge;
            break;
          default:
            throw new Error("Unexpected merge ref " + merge);
        }
        if (mergerange.s.c >= start_col) {
          mergerange.s.c = Math.max(mergerange.s.c - ncols, start_col);
          if (mergerange.e.c < start_col + ncols) {
            delete ws["!merges"][idx];
            return;
          }
          mergerange.e.c -= ncols;
          if (mergerange.e.c < mergerange.s.c) {
            delete ws["!merges"][idx];
            return;
          }
        } else if (mergerange.e.c >= start_col) mergerange.e.c = Math.max(mergerange.e.c - ncols, start_col);
        this.clamp_range(mergerange);
        ws["!merges"][idx] = mergerange;
      });
    if (ws["!merges"])
      ws["!merges"] = ws["!merges"].filter(function (x) {
        return !!x;
      });

    /* cols */
    if (ws["!cols"]) ws["!cols"].splice(start_col, ncols);
  }
}

export default ExcelFileHandler;
