import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { TextField, Button } from "@material-ui/core";

export const OptionsComponent = ({
  permissions,
  optionsRecord,
  submitRecord,
  errorObj,
  lang,
}) => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      bankaccountname: optionsRecord.bankaccountname,
      iban: optionsRecord.iban,
      bankname: optionsRecord.bankname,
      companyip: optionsRecord.companyip,
      apiautoretriescount: optionsRecord.apiautoretriescount,
      trafficnetpercentage: optionsRecord.trafficnetpercentage,
    },
  });
  const [state, setState] = useState({
    enableCompanyIp: optionsRecord.enablecompanyip,
    systemAutoMode: optionsRecord.systemautomode,
  });
  useEffect(() => {
    if (errorObj) {
      console.log("setting errors ...");
      Object.keys(errorObj).forEach((key) => {
        setError(key, { type: "custom", message: errorObj[key][0] });
      });
    }
  }, [errorObj]);
  const validationMessages = (error, fieldName = "This field") => {
    if (error.type === "required") {
      return (
        window.sys_app_translate(fieldName, lang) +
        " " +
        window.sys_app_translate("is required", lang)
      );
    }
    if (error.type === "pattern") {
      switch (fieldName) {
        default:
          return (
            window.sys_app_translate(fieldName, lang) +
            " " +
            window.sys_app_translate("is not correct", lang)
          );
      }
    }
    if (error.type === "custom") {
      return error.message;
    }
  };
  const fileRef = useRef(null);
  const onSubmit = (values) => {
    console.log("submitting options ...");
    optionsRecord.bankaccountname = values.bankaccountname;
    optionsRecord.iban = values.iban;
    optionsRecord.bankname = values.bankname;
    optionsRecord.enablecompanyip = state.enableCompanyIp;
    optionsRecord.companyip = values.companyip;
    optionsRecord.apiautoretriescount = values.apiautoretriescount;
    optionsRecord.trafficnetpercentage = values.trafficnetpercentage;
    optionsRecord.systemautomode = state.systemAutoMode;
    submitRecord(optionsRecord);
    return;
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/*bankaccountname*/}
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-10 offset-1 col-sm-4 offset-sm-0">
            <Controller
              name="bankaccountname"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  id="BankAccountName"
                  className="itqan-text-field"
                  label={window.sys_app_translate("Bank Account Name", lang)}
                  variant="outlined"
                  error={errors.bankaccountname ? true : false}
                  helperText={
                    errors.bankaccountname &&
                    validationMessages(errors.bankaccountname, "Note")
                  }
                  {...field}
                />
              )}
            />
          </div>
        </div>
        {/*iban*/}
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-10 offset-1 col-sm-4 offset-sm-0">
            <Controller
              name="iban"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  id="IBAN"
                  className="itqan-text-field"
                  label={window.sys_app_translate("IBAN", lang)}
                  variant="outlined"
                  error={errors.iban ? true : false}
                  helperText={
                    errors.iban && validationMessages(errors.iban, "Note")
                  }
                  {...field}
                />
              )}
            />
          </div>
        </div>
        {/*bankname*/}
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-10 offset-1 col-sm-4 offset-sm-0">
            <Controller
              name="bankname"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  id="BankName"
                  className="itqan-text-field"
                  label={window.sys_app_translate("Bank Name", lang)}
                  variant="outlined"
                  error={errors.bankname ? true : false}
                  helperText={
                    errors.bankname &&
                    validationMessages(errors.bankname, "Note")
                  }
                  {...field}
                />
              )}
            />
          </div>
        </div>
        {/*trafficnetpercentage*/}
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-10 offset-1 col-sm-2 offset-sm-0">
            <Controller
              name="trafficnetpercentage"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  id="TrafficNetPercentage"
                  type="number"
                  className="itqan-text-field"
                  label={window.sys_app_translate(
                    "Traffic Net Percentage",
                    lang
                  )}
                  variant="outlined"
                  error={errors.trafficnetpercentage ? true : false}
                  helperText={
                    errors.trafficnetpercentage &&
                    validationMessages(errors.trafficnetpercentage, "Note")
                  }
                  {...field}
                />
              )}
            />
          </div>
        </div>
        {/*apiautoretrycount*/}
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-10 offset-1 col-sm-2 offset-sm-0">
            <Controller
              name="apiautoretriescount"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  id="APIAutoRetryCount"
                  type="number"
                  className="itqan-text-field"
                  label={window.sys_app_translate("API auto retry count", lang)}
                  variant="outlined"
                  error={errors.apiautoretrycount ? true : false}
                  helperText={
                    errors.apiautoretrycount &&
                    validationMessages(
                      errors.apiautoretrycount,
                      "API Auto Retry Count"
                    )
                  }
                  {...field}
                />
              )}
            />
          </div>
        </div>
        {/*companyip*/}
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-10 offset-1 col-sm-2 offset-sm-0">
            <Controller
              name="companyip"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  id="TrafficNetPercentage"
                  className="itqan-text-field"
                  label={window.sys_app_translate("Company IP", lang)}
                  variant="outlined"
                  error={errors.companyip ? true : false}
                  helperText={
                    errors.companyip &&
                    validationMessages(errors.companyip, "Note")
                  }
                  {...field}
                />
              )}
            />
          </div>
        </div>
        {/*enablecompanyip*/}
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-10 offset-1 col-sm-4 offset-sm-0">
            <div
              className="form-check form-switch table-activate-button"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2.12rem",
              }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={state.enableCompanyIp}
                onChange={(e) => {
                  setState({
                    ...state,
                    enableCompanyIp: e.target.checked,
                  });
                }}
              />

              <label
                className="form-check-label inter inter-medium"
                style={{
                  color: "#199B8B",
                  marginRight: "18px",
                  marginLeft: "6px",
                  fontSize: "16px",
                }}
              >
                {window.sys_app_translate(
                  "Enable Company IP Restriction",
                  lang
                )}
              </label>
            </div>
          </div>
        </div>
        {/*systemautomode*/}
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-10 offset-1 col-sm-4 offset-sm-0">
            <div
              className="form-check form-switch table-activate-button"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2.12rem",
              }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={state.systemAutoMode}
                onChange={(e) => {
                  setState({
                    ...state,
                    systemAutoMode: e.target.checked,
                  });
                }}
              />
              <label
                className="form-check-label inter inter-medium"
                style={{
                  color: "#199B8B",
                  marginRight: "18px",
                  marginLeft: "6px",
                  fontSize: "16px",
                }}
              >
                {window.sys_app_translate(
                  "(Auto Mode) Use Insurance Companies APIs",
                  lang
                )}
              </label>
            </div>
          </div>
        </div>
        {/*Submit button*/}
        <div
          className="row d-flex justify-content-start form-row"
          style={{ marginTop: "1rem" }}
        >
          <div className="col-10 offset-1 col-sm-6 offset-sm-0 d-flex justify-content-start">
            <Button
              variant="contained"
              style={{ display: "inline" }}
              type="submit"
              className="itqan-form-button"
              onClick={() => {}}
            >
              {window.sys_app_translate("Update", lang)}
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
