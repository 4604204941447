import React from "react";
import { useState } from "react";
import CheckboxGroup from "./ITQCheckboxGroup";

export const ITQCheckboxes = ({
  settings,
  lang,
  onSettingChecked,
  hideTitle = false,
}) => {
  const [key, setKey] = useState(Object.keys(settings)[0]);
  const keyHandler = (key) => {
    setKey(key);
  };
  const constituteThreeSettings = () => {
    let subKeys = Object.keys(settings[key]),
      objects = [];
    subKeys = subKeys.filter((key) => key !== "undefined");
    let count = 1,
      sum = 1,
      obj = {};
    subKeys.forEach((subKey) => {
      if (subKey !== "undefined") {
        obj[subKey] = settings[key][subKey];
        if (count === 3 || sum === subKeys.length) {
          objects.push({ ...obj });
          obj = {};
          count = 0;
        }
        count++;
        sum++;
      }
    });
    return (
      <div className="itqan-checkbox">
        <div className="container">
          {!hideTitle && (
            <div className="itqan-checkbox-title">
              <h2
                style={{ fontSize: "1.25rem", marginTop: "3.75rem" }}
                className="inter inter-semi-bold"
              >
                {window.sys_app_translate("Permission", lang)}:
              </h2>
            </div>
          )}
          <div action="" className="itqan-checkbox-form">
            {objects.map((object, index) => {
              return (
                <CheckboxGroup
                  key={"Checkboxgroup-" + index}
                  settings={object}
                  onSettingChecked={(subKey) => {
                    onSettingChecked(key, subKey);
                  }}
                  lang={lang}
                  hideTitle={hideTitle}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className="itqan-grouped-checkboxes">
        <div className="container">
          {
            <div className="itqan-grouped-checkboxes-title">
              <h2
                style={{ fontSize: "1.25rem", marginTop: "2.5rem" }}
                className="inter inter-semi-bold"
              >
                {!hideTitle &&
                  window.sys_app_translate("Main Role", lang) + ":"}
                {hideTitle &&
                  window.sys_app_translate("Insurance Types", lang) + ":"}
              </h2>
            </div>
          }
          <div className="itqan-grouped-checkboxes-wrp">
            {Object.keys(settings).map((settingKey, index) => {
              return (
                !hideTitle && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      keyHandler(settingKey);
                    }}
                    key={"setting" + index}
                    className={
                      "itqan-grouped-checkboxes-btn inter inter-semi-bold " +
                      (key === settingKey ? "active" : "")
                    }
                  >
                    {window.sys_app_translate(settingKey, lang)}
                  </button>
                )
              );
            })}
          </div>
        </div>
      </div>
      <div className="space"></div>
      {constituteThreeSettings()}
    </React.Fragment>
  );
};
