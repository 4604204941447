/**
 * Released Profits
 */
/* eslint-disable */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// async components
import {
  AsyncManageReleasedProfitsComponent,
  AsyncAddReleasedProfitComponent,
  AsyncModifyReleasedProfitComponent,
} from "../../components/AsyncComponent/AsyncComponent";

const Forms = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/manage`} />
      <Route
        path={`${match.url}/view-all`}
        component={AsyncManageReleasedProfitsComponent}
      />
    </Switch>
  </React.Fragment>
);

export default Forms;
