import React, { useRef, useEffect, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Slider } from "@material-ui/core";
import { Icon } from "@iconify/react/dist/iconify.js";
import AppConfig from "../constants/AppConfig";

const ImageCropper = ({ imageFile, onCropComplete }) => {
  const cropperRef = useRef(null);
  const currentRotation = useRef(0);
  const [aspectRatio, setAspectRatio] = useState(null);
  useEffect(() => {
    if (imageFile) {
      const image = new Image();
      image.onload = () => {
        setAspectRatio(image.width / image.height);
      };
      image.src = URL.createObjectURL(imageFile);
    }
  }, [imageFile]);

  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();

    croppedCanvas.toBlob((blob) => {
      onCropComplete(new File([blob], imageFile.name, { type: "image/png" }));
    });
  };

  return (
    <>
      <div className="text-center">
        <Cropper
          src={URL.createObjectURL(imageFile)}
          style={{
            height: imageFile.height,
            width: "100%",
            maxHeight: "500px",
          }}
          aspectRatio={aspectRatio}
          guides={true}
          ref={cropperRef}
          zoomable={true}
          rotatable={true}
          ready={() => {
            const imageData = cropperRef.current.cropper.getImageData();
            // Set the crop box dimensions to match the image's natural dimensions
            cropperRef.current.cropper.setCropBoxData({
              left: imageData.left,
              top: imageData.top,
              width: imageData.naturalWidth,
              height: imageData.naturalHeight,
            });
          }}
        />
      </div>
      <div className="mt-2 my-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-1">
              <Icon
                icon={"ic:twotone-crop-rotate"}
                fontSize={"1.5rem"}
                color={AppConfig.themeColors.secondary}
              />
            </div>
            <div className="col-11 d-flex align-items-center">
              <Slider
                onChange={(e, newValue) => {
                  cropperRef.current.cropper.reset();
                  cropperRef.current.cropper.rotate(newValue);
                }}
                defaultValue={0}
                min={-180}
                max={180}
                step={0.00001}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="px-3 py-2"
        style={{
          border: `1px solid ${AppConfig.themeColors.greyLighten}`,
          borderRadius: "5px",
        }}
      >
        <div className="d-flex justify-content-around align-items-center">
          <Icon
            icon={"iconamoon:zoom-in-light"}
            fontSize={"2.5rem"}
            color={AppConfig.themeColors.secondary}
            onClick={() => {
              cropperRef.current.cropper.zoom(0.1);
            }}
            role="button"
          />
          <Icon
            icon={"iconamoon:zoom-out-light"}
            fontSize={"2.5rem"}
            color={AppConfig.themeColors.secondary}
            onClick={() => {
              cropperRef.current.cropper.zoom(-0.1);
            }}
            role="button"
          />
          <Icon
            icon={"ph:flip-horizontal"}
            fontSize={"2.5rem"}
            color={AppConfig.themeColors.secondary}
            onClick={() => {
              cropperRef.current.cropper.scaleX(-1);
            }}
            role="button"
          />
          <Icon
            icon={"ph:flip-vertical"}
            fontSize={"2.5rem"}
            color={AppConfig.themeColors.secondary}
            onClick={() => {
              cropperRef.current.cropper.scaleY(-1);
            }}
            role="button"
          />
          <Icon
            icon={"hugeicons:rotate-right-02"}
            fontSize={"2.5rem"}
            color={AppConfig.themeColors.secondary}
            onClick={() => {
              cropperRef.current.cropper.rotate(90);
            }}
            role="button"
          />
          <Icon
            icon={"hugeicons:rotate-left-02"}
            fontSize={"2.5rem"}
            color={AppConfig.themeColors.secondary}
            onClick={() => {
              cropperRef.current.cropper.rotate(-90);
            }}
            role="button"
          />
          <Icon
            icon={"lets-icons:done-round-fill"}
            fontSize={"2.5rem"}
            color={AppConfig.themeColors.primary}
            onClick={handleCrop}
            role="button"
          />
        </div>
      </div>
    </>
  );
};

export default ImageCropper;
