import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "../../../axios";
import ITQANDropDown from "../../../libs/ITQANDropdown";
import { Editor } from "@tinymce/tinymce-react";
import { MessageTypeCustom } from "../../../libs";
import moment from "moment";
import { Button } from "@material-ui/core";

export default function SendMessageComponent({
  agencies,
  showMessage,
  setLoading,
  handleAppError,
  lang,
}) {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    historyObj,
  } = useForm({
    defaultValues: {},
  });
  const [state, setState] = useState({
    selectedAgencies: null,
    type: MessageTypeCustom,
  });
  const editorRef = useRef(null);
  const validateEntry = (values) => {
    let message = editorRef.current.getContent().replace(/<[^>]*>/g, "");
    if (values.type.value === MessageTypeCustom && !message) {
      showMessage({
        type: "warning",
        title: window.sys_app_translate("Message is empty!", lang),
        message: window.sys_app_translate("Please fill in the message", lang),
      });
      return false;
    }
    if (
      values.type.value === MessageTypeCustom &&
      (!state.selectedAgencies || state.selectedAgencies.length === 0)
    ) {
      showMessage({
        type: "warning",
        title: window.sys_app_translate("No Selected Agencies!", lang),
        message: window.sys_app_translate("Please Select Agencies", lang),
      });
      return false;
    }
  };
  const onSubmit = async (values) => {
    console.log(values);
    const { selectedAgencies } = state;
    try {
      setLoading(true);
      if (!validateEntry) {
        return;
      }
      const agenciesFilterRelatedValue = selectedAgencies.join(",");
      const dataToPost = {
        agencies: agenciesFilterRelatedValue,
        type: values.type,
        date: moment(new Date()).format("YYYY-MM-DD"),
      };
      if (values.maximumdebt) {
        dataToPost.maximumdebt = values.maximumdebt;
      }
      if (values.minimumdebt) {
        dataToPost.minimumdebt = values.minimumdebt;
      }
      let message = null;
      if (editorRef && editorRef.current) {
        dataToPost.message = editorRef.current
          .getContent()
          .replace(/<[^>]*>/g, "");
      }
      await axios.post("/message-requests", dataToPost);
      showMessage({
        type: "success",
        title: window.sys_app_translate("Successful Operation!", lang),
        message: window.sys_app_translate(
          "Message request successfully sent!",
          lang
        ),
      });
      historyObj.replace("/app/message-requests/view-all");
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="container-fluid"
          style={{
            backgroundColor: "white",
            borderRadius: "25px",
            padding: "1rem",
          }}
        >
          {agencies && (
            <>
              <div className="row my-2">
                <div className="col-12">
                  <select
                    className="form-select"
                    {...register("type", {
                      required: true,
                      onChange: (e) => {
                        setState({
                          ...state,
                          type: e.target.value,
                        });
                      },
                    })}
                    name="type"
                  >
                    <option value={MessageTypeCustom}>
                      {window.sys_app_translate("Custom", lang)}
                    </option>
                  </select>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12">
                  {agencies && (
                    <ITQANDropDown
                      placeholder={
                        state.type === MessageTypeCustom
                          ? window.sys_app_translate("Select Agencies", lang)
                          : window.sys_app_translate("Or choose Agencies", lang)
                      }
                      fullWidth={true}
                      optionsP={agencies}
                      lang={lang}
                      optionsSelected={(selectedAgencies) => {
                        setState({
                          ...state,
                          selectedAgencies: selectedAgencies,
                        });
                      }}
                    />
                  )}
                </div>
              </div>

              {state.type === MessageTypeCustom && (
                <div className="row my-2">
                  <div className="col-12">
                    {editorRef && (
                      <Editor
                        apiKey="kixvq9txpdhvcsxini14uqndp8bknlnab77jk3omh9uud25m"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue=""
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: ["emoticons"],
                          toolbar: "emoticons",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="row my-2">
                <div className="col-12">
                  <Button
                    variant="contained"
                    style={{ width: "100%" }}
                    className="inter inter-bold"
                    type="submit"
                  >
                    {window.sys_app_translate("Send", lang)}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}
