const firebaseConfigProduction = {
  apiKey: "AIzaSyBWSjt0rElgEiWugNamuAlYBrY0L6wdTK8",
  databaseURL: "https://itqan-education-test-default-rtdb.firebaseio.com",
  authDomain: "itqan-education-test.firebaseapp.com",
  projectId: "itqan-education-test",
  storageBucket: "itqan-education-test.appspot.com",
  messagingSenderId: "335208262261",
  appId: "1:335208262261:web:a368eb2751ef20b30e263c",
};
const firebaseConfigDevelopment = {
  apiKey: "AIzaSyDbqi1NPIkqYF8Zby6Cbz0SYBXi84rjUDo",
  authDomain: "itqan-education-system-test.firebaseapp.com",
  databaseURL:
    "https://itqan-education-system-test-default-rtdb.firebaseio.com",
  projectId: "itqan-education-system-test",
  storageBucket: "itqan-education-system-test.appspot.com",
  messagingSenderId: "744120312320",
  appId: "1:744120312320:web:d6726383085dbdf8cb2ee5",
};
export default process.env.NODE_ENV === "production"
  ? window.location.host === "itqanapply.com"
    ? firebaseConfigProduction
    : firebaseConfigDevelopment
  : firebaseConfigDevelopment;
